import { NavLink } from 'react-router-dom';

import { List, ListItemButton, Box } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { theme } from '@app/app/configs/theme';

interface MenuItem {
  readonly name: string;
  readonly to: string;
  readonly show?: boolean;
  readonly exact?: boolean;
  readonly disabled?: boolean;
  readonly onClick?: () => void;
  readonly active?: boolean;
}
export interface Props {
  readonly menu: MenuItem[];
  readonly variant?: 'vertical' | 'horizontal';
  readonly sx?: SxProps<Theme>;
}

export const Menu = ({ menu, variant = 'horizontal', sx }: Props) => {
  return (
    <List
      component="nav"
      sx={[
        {
          padding: 0,
        },
        variant === 'horizontal' && {
          display: 'flex',
          alignItems: 'center',

          '& .nav-menu-item': {
            '&:not(:last-child)': {
              marginRight: {
                xs: '10px',
                md: '40px',
              },
            },
          },
        },
        variant === 'vertical' && {
          '& .nav-menu-item': {
            borderBottom: `1px solid ${theme.palette.grey[500]}`,
            '&:first-child': {
              borderTop: `1px solid ${theme.palette.grey[500]}`,
            },
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {menu.map(
        ({ name, to, show = true, exact = false, disabled = false, onClick, active = false }) =>
          show && (
            <ListItemButton
              className="nav-menu-item"
              sx={[
                {
                  fontSize: 14,
                  color: 'text.primary',
                  paddingLeft: '3px',
                  paddingRight: '3px',
                  position: 'relative',

                  '&:after': {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    left: variant === 'horizontal' ? 0 : '',
                    top: variant === 'vertical' ? 0 : '',
                    height: variant === 'horizontal' ? 3 : 'auto',
                    width: variant === 'vertical' ? 3 : 'auto',
                    backgroundColor: 'transparent',
                    zIndex: 1,
                  },
                },
                active && {
                  fontWeight: 600,

                  '&:after': {
                    bgcolor: 'primary.main',
                  },
                },
              ]}
              key={name}
              component={NavLink}
              to={to}
              onClick={onClick}
              exact={exact}
              disabled={disabled}
              selected={false}
            >
              <Box
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                {name}
              </Box>
            </ListItemButton>
          ),
      )}
    </List>
  );
};
