import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useFormikContext } from 'formik';

export interface DynamicFormFieldInformationProps {
  readonly name: string;
  readonly template: any;
  readonly variables?: Record<any, any>;
  readonly pageInner?: boolean;
}

export const DynamicFormFieldInformation = memo(
  ({ name, template, variables, pageInner }: DynamicFormFieldInformationProps) => {
    const { t } = useTranslation('common');

    const { values } = useFormikContext<Record<any, any>>();

    const fullValues = { ...variables, ...values };

    return (
      <>
        {template.map((item: any) => {
          return (
            <Box
              key={item}
              sx={{
                fontSize: pageInner ? 12 : 14,
                color: pageInner ? 'text.secondary' : 'text.primary',
                '&:not(:last-of-type)': { mb: 3 },
              }}
            >
              {Array.isArray(item) &&
                item.map((listItem: string) => (
                  <Box key={listItem} display="flex" alignItems="center" mb={2}>
                    <CheckIcon color="primary" sx={{ mr: 2, fontSize: 20 }} />
                    <Typography fontSize="inherit">{t(`application.field.${name}.${listItem}`, fullValues)}</Typography>
                  </Box>
                ))}

              {typeof item === 'string' && (
                <Typography fontSize="inherit">{t(`application.field.${name}.${item}`, fullValues)}</Typography>
              )}
            </Box>
          );
        })}
      </>
    );
  },
);
