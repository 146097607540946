import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

export const useScrollToTop = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (history.action !== 'POP') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [location]);
};
