import { ReactNode, useState, memo } from 'react';
import dayJs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { IconButton, InputAdornment } from '@mui/material';
import { DesktopDatePicker, DesktopDatePickerProps } from '@mui/x-date-pickers/DesktopDatePicker';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import { appConfig } from '@app/app';

import { Text } from './Text';
import { TextLabel } from './TextLabel';

dayJs.extend(utc);

export interface DateProps
  extends Omit<DesktopDatePickerProps<any, any>, 'renderInput' | 'onChange' | 'value' | 'inputFormat'> {
  readonly helperText?: ReactNode;
  readonly error?: boolean;
  readonly placeholder?: string;
  readonly onChange: (value?: string) => void;
  readonly disableManual?: boolean;
  readonly value?: string;
  readonly format?: string;
  readonly readOnly?: boolean;
  readonly required?: boolean;
  readonly description?: string;
  readonly name?: string;
}

export const Date = memo(
  ({
    value,
    helperText,
    error,
    placeholder,
    onChange,
    format = appConfig.format.date,
    readOnly = false,
    disableManual = false,
    disabled,
    required,
    description,
    label,
    name,
    ...props
  }: DateProps) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <DesktopDatePicker
        open={isOpen}
        readOnly={readOnly}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        label={label && <TextLabel label={label} required={required} description={description} />}
        {...props}
        PopperProps={{
          placement: 'bottom-end',
          ...props.PopperProps,
        }}
        value={value ? dayJs(value, appConfig.format.isoDateTime) : value || null}
        inputFormat={format}
        onChange={(date: Dayjs | null) => {
          onChange(date ? date.format(appConfig.format.isoDateTime) : '');
        }}
        disabled={disabled}
        renderInput={({ InputProps, inputProps, ...params }) => {
          return (
            <Text
              {...params}
              name={name}
              helperText={helperText}
              error={error}
              inputProps={{ ...inputProps, placeholder, readOnly: disableManual || readOnly }}
              InputProps={{
                ...InputProps,
                sx: {
                  position: 'relative',
                  pr: 0,
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      position: 'absolute',
                      right: 0,
                      top: '50%',
                    }}
                  >
                    {!readOnly && (
                      <IconButton color="inherit" onClick={() => setIsOpen(true)} disabled={disabled}>
                        <CalendarTodayIcon fontSize="medium" />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          );
        }}
      />
    );
  },
);
