import { useCallback, useState } from 'react';

import { cognitoClient } from '../configs/cognitoClient';
import { useAuth } from './useAuth';
import { PasswordResetForm, SignInForm } from '../types/forms';

export const useAuthActions = () => {
  const { updateState } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const signIn = useCallback(async ({ email, ...data }: SignInForm) => {
    setIsLoading(true);

    try {
      const auth = await cognitoClient.signIn({ username: email?.toLowerCase(), ...data });

      const token = auth.getSignInUserSession().getIdToken().getJwtToken();

      updateState({ token, user: undefined });
    } catch (e) {
      throw e;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const signUpConfirm = useCallback(async ({ username, code }: { username: string; code: string }) => {
    setIsLoading(true);

    try {
      await cognitoClient.confirmSignUp(username, code);
    } catch (e) {
      throw e;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const signOut = useCallback(async () => {
    setIsLoading(true);

    try {
      await cognitoClient.signOut();

      updateState({ user: undefined, token: undefined });
    } catch (e) {
      throw e;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const forgotPassword = useCallback(async ({ email }: PasswordResetForm) => {
    setIsLoading(true);

    try {
      await cognitoClient.forgotPassword(email?.toLowerCase());
    } catch (e) {
      throw e;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const forgotPasswordConfirm = useCallback(
    async ({ username, code, password }: { username: string; code: string; password: string }) => {
      setIsLoading(true);

      try {
        await cognitoClient.forgotPasswordSubmit(username.toLowerCase(), code, password);
      } catch (e) {
        throw e;
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  const changePassword = useCallback(
    async ({ oldPassword, newPassword }: { oldPassword: string; newPassword: string }) => {
      setIsLoading(true);

      try {
        const user = await cognitoClient.currentAuthenticatedUser();

        await cognitoClient.changePassword(user, oldPassword, newPassword);
      } catch (e) {
        throw e;
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  const changeEmail = useCallback(async ({ newEmail, password }: { newEmail: string; password: string }) => {
    setIsLoading(true);

    try {
      const user = await cognitoClient.currentAuthenticatedUser();

      await cognitoClient.updateUserAttributes(user, { email: newEmail });
    } catch (e) {
      throw e;
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    isLoading,
    signIn,
    signUpConfirm,
    signOut,
    forgotPassword,
    forgotPasswordConfirm,
    changePassword,
    changeEmail,
  };
};
