import { AuthLayout } from '@app/layouts';
import { PasswordCreate } from '@app/auth/components/PasswordCreate';

export const PasswordCreatePage = () => {
  return (
    <AuthLayout>
      <PasswordCreate />
    </AuthLayout>
  );
};
