import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import PubSub from 'pubsub-js';

import { getIdToken } from '@app/auth/utils/cognito';

const apolloHttpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_HOST}/graphql`,
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    const isUnauthorizedError = graphQLErrors.some(row =>
      [401, 403].includes((row.extensions as any)?.response?.statusCode),
    );

    if (isUnauthorizedError) {
      PubSub.publish('signOut');
    }
  }
});

const apolloLink = setContext(async (_, { headers }) => {
  const jwt = await getIdToken().catch(e => console.error(e));

  return {
    headers: {
      ...headers,
      ...(jwt && {
        Authorization: `Bearer ${jwt}`,
      }),
    },
  };
});

export const apolloClient = new ApolloClient({
  link: apolloLink.concat(errorLink).concat(apolloHttpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          applicationGetOne: {
            read(_, { args, toReference }) {
              return toReference({
                __typename: 'Application',
                id: args?.id,
              });
            },
          },
        },
      },
    },
  }),
});
