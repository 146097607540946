import { useCallback } from 'react';
import { IconButton, Box } from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutline';

export interface UploadFileItemProps {
  readonly value: Record<any, any>;
  readonly error?: boolean;
  readonly onDelete: (value: Record<any, any>) => void;
  readonly onDownload: (value: Record<any, any>) => void;
  readonly disabled?: boolean;
}

export const UploadFileItem = ({ value, error, onDelete, onDownload, disabled }: UploadFileItemProps) => {
  const handleDelete = useCallback(() => {
    onDelete(value);
  }, [onDelete, value]);

  const handleDownload = useCallback(() => {
    onDownload(value);
  }, [onDownload, value]);

  return (
    <Box
      sx={[
        {
          bgcolor: error ? 'error.light' : 'grey.200',
          color: error ? 'error.main' : 'text.primary',
          fontSize: 14,
          lineHeight: 1.2,
          py: 1,
          px: 4,
          position: 'relative',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          borderRadius: '8px',
        },
      ]}
    >
      <IconButton
        sx={{ color: 'primary.main', position: 'absolute', top: '50%', left: 5, mt: '-13px' }}
        size="small"
        onClick={handleDownload}
      >
        <SaveAltIcon fontSize="inherit" />
      </IconButton>

      {value.name}

      <IconButton
        disabled={disabled}
        sx={{
          color: 'text.secondary',
          position: 'absolute',
          top: '50%',
          right: 3,
          mt: '-13px',
          '&:hover': { color: 'error.main' },
        }}
        size="small"
        onClick={handleDelete}
      >
        <DeleteOutlinedIcon fontSize="inherit" />
      </IconButton>
    </Box>
  );
};
