import { useMemo } from 'react';
import { LinearProgress } from '@app/ui/progress';
import { GridRenderCellParams } from '@mui/x-data-grid';

import { calculateSurveyProgress } from '@app/dynamic/utils';
import { applicationConfig } from '@app/application/configs';

export const SurveyProgress = ({ row }: GridRenderCellParams) => {
  const progress = useMemo(() => calculateSurveyProgress(applicationConfig.surveys, row), [row]);

  return <LinearProgress value={progress.progressTotal} />;
};
