import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

export const TableNoRows = () => {
  const { t } = useTranslation('common');
  return (
    <Box
      sx={{
        color: 'text.secondary',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: 3,
      }}
    >
      <span>{t('general.table.noData')}</span>
    </Box>
  );
};
