import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button, Box } from '@mui/material';

import { Modal } from '@app/ui/modal';
import { PasswordChangeForm } from '@app/auth/types/forms';
import { useAuthActions } from '@app/auth';
import { Password, yup } from '@app/formik';
import { success } from '@app/snackbars';
import { Loader } from '@app/ui/loader';

const initialValues = {
  oldPassword: '',
  newPassword: '',
};

export interface ChangePasswordModalProps {
  readonly open: boolean;
  readonly onClose: () => void;
}

export const ChangePasswordModal = ({ open, onClose }: ChangePasswordModalProps) => {
  const { t } = useTranslation('common');
  const { isLoading, changePassword } = useAuthActions();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        oldPassword: yup.string().required(),
        newPassword: yup
          .string()
          .trim()
          .test('test-pass', t('general.validation.passwordStrong') as string, value =>
            Boolean(
              value &&
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`])\S{8,99}$/.test(value),
            ),
          )
          .test('passwords-match', t('general.validation.passwordDifferent') as string, function (value) {
            return this.parent.oldPassword !== value;
          })
          .required(),
      }),
    [t],
  );

  const handleSubmit = useCallback(
    async (
      data: Omit<PasswordChangeForm, 'username'>,
      helpers: FormikHelpers<Omit<PasswordChangeForm, 'username'>>,
    ) => {
      try {
        await changePassword(data);

        onClose();
        success(t('auth.page.passwordCreate.successMessage'));
      } catch (e: any) {
        helpers.setErrors({ oldPassword: t(`profile.validation.${e.code}`, e.message) as unknown as string });
      }
    },
    [t],
  );

  return (
    <Modal open={open} onClose={onClose} maxWidth="sm" title={t('profile.modal.password.title')}>
      <Loader isLoading={isLoading} />
      <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        {() => (
          <Form noValidate>
            <Password label={t('user.field.enterOldPassword.label')} required name="oldPassword" margin="normal" />

            <Password
              label={t('user.field.enterNewPassword.label')}
              description={t('general.validation.passwordStrong')}
              required
              name="newPassword"
              margin="normal"
            />

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', pt: 1 }}>
              <Button color="primary" onClick={onClose}>
                {t('general.button.cancel')}
              </Button>

              <Button color="primary" variant="contained" type="submit">
                {t('general.button.confirm')}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
