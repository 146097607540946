import { useMemo } from 'react';
import { usePrevious } from 'react-use';
import { DataGrid, DataGridProps, GridValueGetterParams } from '@mui/x-data-grid';

import { ReactComponent as SortIcon } from '@app/app/images/icons/icon-sort.svg';
import { ReactComponent as SortIconUp } from '@app/app/images/icons/icon-sort-up.svg';
import { ReactComponent as SortIconDown } from '@app/app/images/icons/icon-sort-down.svg';

import { TableNoRows } from './TableNoRows';
import { TableState } from '../types/TableState';

const rowsPerPageOptions: DataGridProps['rowsPerPageOptions'] = [30, 60, 100];

const components: DataGridProps['components'] = {
  ColumnUnsortedIcon: ({ sortingOrder, ...rest }) => <SortIcon {...rest} />,
  ColumnSortedAscendingIcon: SortIconUp,
  ColumnSortedDescendingIcon: SortIconDown,
  NoRowsOverlay: TableNoRows,
};

const initialState: DataGridProps['initialState'] = { pagination: { page: 0 } };

export interface TableProps extends DataGridProps {
  //TODO: Omit
  readonly state: TableState;
  readonly onChangeState: (stata: Partial<TableState>) => void;
}

export const Table = ({ state, onChangeState, rowCount, columns, ...props }: TableProps) => {
  const previousRowCount = usePrevious(rowCount);

  const patchedColumns = useMemo(
    () =>
      columns.map(column => {
        if (column?.field?.includes('.')) {
          return {
            ...column,
            valueGetter: (params: GridValueGetterParams) =>
              params.field.split('.').reduce((a: Record<any, any>, b: string) => a[b] || '', params.row),
          };
        }

        return column;
      }),
    [columns],
  );

  return (
    <DataGrid
      {...props}
      autoHeight
      pagination
      disableColumnMenu
      headerHeight={50}
      rowHeight={60}
      rowSpacingType="border"
      sortingMode="server"
      paginationMode="server"
      columns={patchedColumns}
      initialState={initialState}
      components={components}
      rowsPerPageOptions={rowsPerPageOptions}
      rowCount={rowCount || previousRowCount || 0}
      pageSize={state.limit}
      onPageSizeChange={limit => onChangeState({ limit })}
      sortModel={state.orderBy?.map(({ direction, field }) => ({ field, sort: direction }))}
      onSortModelChange={orderBy =>
        onChangeState({ orderBy: orderBy.map(({ sort, field }) => ({ field, direction: sort })) })
      }
      page={state.page - 1}
      onPageChange={page => onChangeState({ page: page + 1 })}
    />
  );
};
