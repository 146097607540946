import { useField } from 'formik';
import { SyntheticEvent, useCallback } from 'react';

import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
} from '@app/ui/forms/components/Autocomplete';
import { Option } from '@app/ui/forms/types';

import { useErrorTranslations } from '../../hooks/useErrorTranslations';

export type AutocompleteProps = Omit<MuiAutocompleteProps, 'value'> & { readonly name: string };

export const Autocomplete = ({ name, helperText, ...props }: AutocompleteProps) => {
  const [{ value }, { touched, error }, { setValue }] = useField(name);
  const errorHelperText = useErrorTranslations(name, error);

  const isError = Boolean(touched && errorHelperText);

  const handleChange = useCallback((event: SyntheticEvent, newValue: Option) => {
    setValue(newValue?.id);
  }, []);

  return (
    <MuiAutocomplete
      {...props}
      value={value}
      onChange={handleChange}
      error={isError}
      helperText={isError ? errorHelperText : helperText || ''}
    />
  );
};
