import { ReactNode } from 'react';

import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';
import Box from '@mui/material/Box';

export interface Props extends DrawerProps {
  readonly handleClose: () => void;
  readonly keepMounted?: boolean;
  readonly hideBackdrop?: boolean;
  readonly header?: ReactNode | boolean;
}

export const Drawer = ({
  children,
  open,
  anchor,
  handleClose,
  keepMounted = false,
  hideBackdrop = false,
  header,
}: Props) => {
  return (
    <MuiDrawer
      sx={{
        '& .MuiDrawer-paper': {
          width: 274,
          boxShadow: 'none',
        },
      }}
      anchor={anchor}
      variant="temporary"
      open={open}
      onClose={handleClose}
      elevation={0}
      ModalProps={{
        keepMounted,
        hideBackdrop,
      }}
    >
      {header && (
        <Box
          sx={{
            position: 'relative',
            padding: '24px 16px 10px 40px',
          }}
        >
          {header}
        </Box>
      )}
      <Box
        sx={{
          padding: '10px 0 16px 40px',
          overflowY: 'auto',
        }}
      >
        {children}
      </Box>
    </MuiDrawer>
  );
};
