import {
  DynamicFormArrayType,
  DynamicFormConfigSurvey,
  DynamicFormElementType,
  DynamicFormElementWidthMode,
} from '@app/dynamic';
import { inArray } from '@app/dynamic/utils/array';

import {
  ApplicationPdf,
  ApplicationSurveyEnum,
  numberOfJobsOptions,
  numberOfMedicalInsuranceOptions,
  MilitaryStatusEnum,
  EmploymentTypeEnum,
  PayTypeEnum,
  StateDisabilityInsuranceEnum,
  StateFundedEnum,
  ProvidedThroughEnum,
} from '../constants';
import cardImage from '../images/card-application-employment.jpg';

export const employmentQuestions: DynamicFormConfigSurvey<ApplicationSurveyEnum> = {
  name: ApplicationSurveyEnum.employmentQuestions,
  meta: {
    cardImage,
    dependOnFields: ['status', 'firstName', 'lastName'],
  },
  pages: [
    {
      name: `${ApplicationSurveyEnum.employmentQuestions}.information`,
      information: true,
      elements: [
        {
          name: `${ApplicationSurveyEnum.employmentQuestions}.information`,
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            variables: {
              time: '5',
            },
            template: [['description.item1', 'description.item2']],
          },
        },
      ],
    },
    {
      name: 'militaryStatus',
      elements: [
        {
          noLabel: true,
          name: 'militaryStatus',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            enum: MilitaryStatusEnum,
          },
        },
      ],
    },
    {
      name: 'employmentStatus',
      elements: [
        {
          name: 'isEmployed',
          type: DynamicFormElementType.booleanRadio,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
              [ApplicationPdf.agency]: true,
            },
          },
        },
        {
          name: 'numberOfJobs',
          type: DynamicFormElementType.text,
          visibleIf: ({ isEmployed }) => isEmployed,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            select: true,
            options: numberOfJobsOptions,
          },
        },
      ],
    },
    {
      name: 'jobs',
      array: {
        type: DynamicFormArrayType.dependent,
        length: ({ numberOfJobs }) => numberOfJobs || 0,
      },
      meta: {
        json: true,
      },
      visibleIf: ({ isEmployed }) => isEmployed,
      elements: [
        {
          name: 'jobs.{index}.employer',
          alias: 'jobs.employer',
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 30,
          },
        },
        {
          name: 'jobs.{index}.employmentType',
          alias: 'jobs.employmentType',
          type: DynamicFormElementType.enumRadio,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            enum: EmploymentTypeEnum,
          },
        },
        {
          name: 'jobs.{index}.payRate',
          alias: 'jobs.payRate',
          type: DynamicFormElementType.moneyText,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
        },
        {
          name: 'jobs.{index}.payType',
          alias: 'jobs.payType',
          type: DynamicFormElementType.enumRadio,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            enum: PayTypeEnum,
          },
        },
        {
          name: 'jobs.{index}.weeklyHoursWorked',
          alias: 'jobs.weeklyHoursWorked',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            number: true,
            length: 3,
          },
        },
        {
          name: 'jobs.{index}.jobTitle',
          alias: 'jobs.jobTitle',
          showDescription: true,
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 30,
          },
        },
        {
          name: 'jobs.{index}.isRequiresLifting',
          alias: 'jobs.isRequiresLifting',
          showDescription: true,
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'jobs.{index}.liftingDescription',
          alias: 'pleaseExplain',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: inArray(({ isRequiresLifting }) => isRequiresLifting),
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 100,
          },
        },
        {
          name: 'jobs.{index}.jobDuties',
          alias: 'jobs.jobDuties',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 300,
          },
        },
      ],
    },
    {
      name: 'stateDisabilityInsurance',
      showDescription: true,
      elements: [
        {
          name: 'stateDisabilityInsurance',
          noLabel: true,
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            enum: StateDisabilityInsuranceEnum,
          },
        },
      ],
    },
    {
      name: 'hasMedicalInsurance',
      showDescription: true,
      elements: [
        {
          name: 'hasMedicalInsurance',
          noLabel: true,
          type: DynamicFormElementType.booleanRadio,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
        },
        {
          name: 'numberOfMedicalInsurance',
          type: DynamicFormElementType.text,
          visibleIf: ({ hasMedicalInsurance }) => hasMedicalInsurance,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            select: true,
            options: numberOfMedicalInsuranceOptions,
          },
        },
      ],
    },
    {
      name: 'medicalInsurances',
      array: {
        type: DynamicFormArrayType.dependent,
        length: ({ numberOfMedicalInsurance }) => numberOfMedicalInsurance || 0,
      },
      meta: {
        json: true,
      },
      visibleIf: ({ hasMedicalInsurance }) => hasMedicalInsurance,
      elements: [
        {
          name: 'medicalInsurances.{index}.provider',
          alias: 'medicalInsurances.provider',
          showDescription: true,
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 30,
          },
        },
        {
          name: 'medicalInsurances.{index}.isStateFunded',
          alias: 'medicalInsurances.isStateFunded',
          showDescription: true,
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            enum: StateFundedEnum,
          },
        },
        {
          name: 'medicalInsurances.{index}.providedThrough',
          alias: 'medicalInsurances.providedThrough',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            enum: ProvidedThroughEnum,
          },
        },
      ],
    },
  ],
};
