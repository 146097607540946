import { ReactNode } from 'react';
import { Container, Box, AppBar, Toolbar, useTheme, Link } from '@mui/material';

import { LogoLink } from './LogoLink';

export interface LightLayoutProps {
  children: ReactNode;
}

export const LightLayout = ({ children }: LightLayoutProps) => {
  const { zIndex } = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
      }}
    >
      <AppBar
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: zIndex.appBar,
        }}
        color="default"
        position="static"
      >
        <Container maxWidth="lg">
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            disableGutters
          >
            <LogoLink />
            <Link
              sx={{
                fontWeight: 700,
                textDecoration: 'none',
                fontSize: { xs: 16, md: 20 },
                '&:hover, &:focus': {
                  opacity: 0.9,
                  color: 'primary.main',
                },
              }}
              href="tel:16195050830"
            >
              619-591-9113
            </Link>
          </Toolbar>
        </Container>
      </AppBar>

      <Container
        maxWidth="md"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          maxWidth: '720px !important',
        }}
      >
        <Box
          component="main"
          sx={{
            minWidth: 0,
            flexGrow: 1,
            pt: {
              xs: 3,
              md: 5,
            },
            pb: 5,
          }}
        >
          {children}
        </Box>
      </Container>
    </Box>
  );
};
