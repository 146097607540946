import { useState, useCallback } from 'react';

export interface UseModalProps {
  readonly onSubmit?: (...rest: any) => void;
  readonly onClose?: () => void;
  readonly defaultIsOpen?: boolean;
}

export const useModal = ({ onSubmit, onClose, defaultIsOpen = false }: UseModalProps = {}) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  const submit = useCallback(
    (...rest: any) => {
      if (onSubmit) {
        onSubmit(...rest);
      }
      setIsOpen(false);
    },
    [onSubmit],
  );

  const close = useCallback(() => {
    if (onClose) {
      onClose();
    }
    setIsOpen(false);
  }, [onClose]);

  const show = useCallback(() => {
    setIsOpen(true);
  }, []);

  return {
    isOpen,
    show,
    submit,
    close,
  };
};
