import { FormattedText, FormattedTextProps } from './FormattedText';

export interface MoneyTextProps extends Omit<FormattedTextProps, 'mask'> {}

export const MoneyText = (props: MoneyTextProps) => {
  return (
    <FormattedText
      {...props}
      blocks={{
        num: {
          mask: Number,
          thousandsSeparator: ',',
          scale: 2,
          radix: '.',
        },
      }}
      maskPlaceholder="$0.00"
      mask="$num"
    />
  );
};
