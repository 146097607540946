import { Font, StyleSheet } from '@react-pdf/renderer';

import { palette } from '@app/app/configs/theme';
import OpenSansRegular from '@app/app/fonts/OpenSans-Regular.ttf';
import OpenSansSemiBold from '@app/app/fonts/OpenSans-SemiBold.ttf';

Font.register({
  family: 'Open Sans',
  format: 'truetype',
  fonts: [{ src: OpenSansRegular }, { src: OpenSansSemiBold, fontWeight: 600 }],
});

export const mainStyles = StyleSheet.create({
  page: {
    fontFamily: 'Open Sans',
    backgroundColor: '#FFF',
    color: palette.text.primary,
    fontSize: 8,
    lineHeight: 1.4,
    border: 0,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  h1: {
    fontSize: 16,
    fontWeight: 600,
  },
  h2: {
    fontSize: 12,
    fontWeight: 600,
  },
  h3: {
    fontSize: 10,
    fontWeight: 600,
  },
  text: {
    fontSize: 9,
    maxWidth: '100%',
  },
  label: {
    color: palette.primary.main,
    fontSize: 7,
    fontWeight: 600,
    marginBottom: 5,
  },
  colorPrimary: {
    color: palette.primary.main,
  },
  bold: {
    fontWeight: 600,
  },
  flex1: {
    flex: 1,
  },
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    paddingHorizontal: 30,
    zIndex: 2,
  },
  content: {
    paddingHorizontal: 30,
    flex: 1,
    zIndex: 1,
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    paddingHorizontal: 30,
    zIndex: 2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
