import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { LightLayout } from '@app/layouts';
import { SignUp } from '@app/auth/components/SignUp';

export const SignUpPage = () => {
  return (
    <LightLayout>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || ''}>
        <SignUp />
      </GoogleReCaptchaProvider>
    </LightLayout>
  );
};
