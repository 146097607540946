import { ReactNode } from 'react';
import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps, Box } from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

export interface TooltipProps extends Omit<MuiTooltipProps, 'children'> {
  readonly children?: ReactNode;
  readonly color?: 'primary.main' | 'inherit';
  readonly display?: 'block' | 'inline-block' | 'flex' | 'inline-flex';
  readonly iconSize?: number | 'inherit';
}

export const Tooltip = ({
  title,
  children,
  color = 'primary.main',
  display = 'inline-flex',
  iconSize = 18,
  placement = 'top',
  sx,
}: TooltipProps) => {
  return (
    <MuiTooltip placement={placement} title={title} enterTouchDelay={100}>
      <Box
        sx={[
          {
            cursor: title ? 'pointer' : 'default',
            maxWidth: '100%',
            verticalAlign: 'middle',
            display,
            color,
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {children || <InfoOutlined sx={{ fontSize: iconSize }} />}
      </Box>
    </MuiTooltip>
  );
};
