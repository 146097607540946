import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikHelpers } from 'formik';
import { Box, Button } from '@mui/material';

import { ScrollToError } from '@app/formik';

import { DynamicFormPageExtended } from './DynamicFormPage';
import { DynamicFormComponentProps, DynamicFormConfigSurvey } from '../types';
import { surveyTransformArrays } from '../utils/array';

export interface DynamicFormProps extends DynamicFormComponentProps<DynamicFormConfigSurvey> {
  readonly onSubmit: (input: Record<any, any>, helpers: FormikHelpers<Record<any, any>>) => void;
  readonly onBack: () => void;
  readonly initialValues: Record<any, any>;
}

export const DynamicForm = ({
  initialValues = {},
  onSubmit,
  onBack,
  translation,
  config,
  ...props
}: DynamicFormProps) => {
  const { t } = useTranslation('common');

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {({ values }) => {
        const isSurveyShouldRebuild = config.array?.length(values);

        return (
          <>
            {useMemo(() => {
              const surveyConfig = surveyTransformArrays(config, values);

              return (
                <Form noValidate autoComplete="off">
                  {surveyConfig.pages.map((page, index) => {
                    return (
                      <DynamicFormPageExtended
                        key={`${page.name}.${index}`}
                        config={page}
                        translation={config.translation ?? translation}
                        values={values}
                        {...props}
                      />
                    );
                  })}

                  <Box
                    className="box-sticky"
                    sx={{
                      display: { sm: 'flex' },
                      justifyContent: 'space-between',
                      borderTop: theme => `1px solid ${theme.palette.grey[500]}`,
                      backgroundColor: '#FFF',
                      px: { xs: 2, sm: 3, md: 5 },
                      mx: { xs: -2, sm: -3, md: -5 },
                      py: { xs: 1, sm: 2 },
                      mb: { xs: -1, sm: -2 },
                      borderBottomLeftRadius: 20,
                      borderBottomRightRadius: 20,
                    }}
                  >
                    <Button
                      sx={{
                        mr: {
                          sm: 1,
                        },
                        mb: {
                          xs: 2,
                          sm: 0,
                        },
                        width: {
                          xs: '100%',
                          sm: 'auto',
                        },
                      }}
                      onClick={onBack}
                    >
                      {t('general.button.back')}
                    </Button>

                    <Button
                      sx={{
                        width: {
                          xs: '100%',
                          sm: 'auto',
                        },
                      }}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      {t('general.button.saveChanges')}
                    </Button>
                  </Box>

                  <ScrollToError />
                </Form>
              );
            }, [isSurveyShouldRebuild])}
          </>
        );
      }}
    </Formik>
  );
};
