import { DynamicFormConfigSurvey, DynamicFormElementType, DynamicFormElementWidthMode } from '@app/dynamic';
import { Option } from '@app/ui/forms/types';

import { ApplicationPdf, ApplicationSurveyEnum, InvolvementEnum, RelationshipStatusEnum } from '../constants';
import cardImage from '../images/card-application-personal-history.jpg';

const showPartnerOption = ({ id }: Option, { relationshipStatus }: Record<any, any> = {}) =>
  id !== InvolvementEnum.partner ||
  [
    RelationshipStatusEnum.married,
    RelationshipStatusEnum.livingTogether,
    RelationshipStatusEnum.engaged,
    RelationshipStatusEnum.divorcedInRelationship,
  ].includes(relationshipStatus);

export const personalHistory: DynamicFormConfigSurvey<ApplicationSurveyEnum> = {
  name: ApplicationSurveyEnum.personalHistory,
  meta: {
    cardImage,
    dependOnFields: ['status', 'firstName', 'lastName', 'relationshipStatus'],
  },
  pages: [
    {
      name: `${ApplicationSurveyEnum.personalHistory}.information`,
      information: true,
      elements: [
        {
          name: `${ApplicationSurveyEnum.personalHistory}.information`,
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            variables: {
              time: '2-10',
            },
            template: [['description.item1', 'description.item2', 'description.item3']],
          },
        },
      ],
    },
    {
      name: 'hasDiploma',
      elements: [
        {
          noLabel: true,
          name: 'hasDiploma',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
      ],
    },
    {
      name: 'bankruptcyHistory',
      meta: {
        json: true,
      },
      elements: [
        {
          name: 'bankruptcyHistory.value',
          alias: 'bankruptcyHistory',
          aliasOption: 'involvement',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          optionVisibleIf: showPartnerOption,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            enum: InvolvementEnum,
          },
        },
        {
          name: 'bankruptcyHistory.year',
          alias: 'year',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ bankruptcyHistory }) =>
            [InvolvementEnum.myself, InvolvementEnum.partner].includes(bankruptcyHistory?.value),
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            multiline: true,
            length: 50,
          },
        },
        {
          name: 'bankruptcyHistory.description',
          alias: 'description',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ bankruptcyHistory }) =>
            [InvolvementEnum.myself, InvolvementEnum.partner].includes(bankruptcyHistory?.value),
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            multiline: true,
            length: 100,
          },
        },
        {
          name: 'divorceOrLegalSeparation.value',
          alias: 'divorceOrLegalSeparation',
          aliasOption: 'involvement',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          optionVisibleIf: showPartnerOption,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            enum: InvolvementEnum,
          },
        },
        {
          name: 'divorceOrLegalSeparation.year',
          alias: 'year',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ divorceOrLegalSeparation }) =>
            [InvolvementEnum.myself, InvolvementEnum.partner].includes(divorceOrLegalSeparation?.value),
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 50,
            multiline: true,
          },
        },
        {
          name: 'divorceOrLegalSeparation.description',
          alias: 'description',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ divorceOrLegalSeparation }) =>
            [InvolvementEnum.myself, InvolvementEnum.partner].includes(divorceOrLegalSeparation?.value),
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 100,
            multiline: true,
          },
        },
        {
          name: 'arrestHistory.value',
          alias: 'arrestHistory',
          aliasOption: 'involvement',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          optionVisibleIf: showPartnerOption,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            enum: InvolvementEnum,
          },
        },
        {
          name: 'arrestHistory.year',
          alias: 'year',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ arrestHistory }) =>
            [InvolvementEnum.myself, InvolvementEnum.partner].includes(arrestHistory?.value),
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 50,
            multiline: true,
          },
        },
        {
          name: 'arrestHistory.description',
          alias: 'description',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ arrestHistory }) =>
            [InvolvementEnum.myself, InvolvementEnum.partner].includes(arrestHistory?.value),
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 100,
            multiline: true,
          },
        },
        {
          name: 'duiHistory.value',
          alias: 'duiHistory',
          aliasOption: 'involvement',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          optionVisibleIf: showPartnerOption,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            enum: InvolvementEnum,
          },
        },
        {
          name: 'duiHistory.year',
          alias: 'year',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ duiHistory }) => [InvolvementEnum.myself, InvolvementEnum.partner].includes(duiHistory?.value),
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 50,
            multiline: true,
          },
        },
        {
          name: 'duiHistory.description',
          alias: 'description',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ duiHistory }) => [InvolvementEnum.myself, InvolvementEnum.partner].includes(duiHistory?.value),
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 100,
            multiline: true,
          },
        },
        {
          name: 'childProtectiveServicesHistory.value',
          alias: 'childProtectiveServicesHistory',
          aliasOption: 'involvement',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          optionVisibleIf: showPartnerOption,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            enum: InvolvementEnum,
          },
        },
        {
          name: 'childProtectiveServicesHistory.year',
          alias: 'year',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ childProtectiveServicesHistory }) =>
            [InvolvementEnum.myself, InvolvementEnum.partner].includes(childProtectiveServicesHistory?.value),
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 50,
            multiline: true,
          },
        },
        {
          name: 'childProtectiveServicesHistory.description',
          alias: 'description',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ childProtectiveServicesHistory }) =>
            [InvolvementEnum.myself, InvolvementEnum.partner].includes(childProtectiveServicesHistory?.value),
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 100,
            multiline: true,
          },
        },
        {
          name: 'childAdoptionHistory.value',
          alias: 'childAdoptionHistory',
          aliasOption: 'involvement',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          optionVisibleIf: showPartnerOption,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            enum: InvolvementEnum,
          },
        },
        {
          name: 'childAdoptionHistory.year',
          alias: 'year',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ childAdoptionHistory }) =>
            [InvolvementEnum.myself, InvolvementEnum.partner].includes(childAdoptionHistory?.value),
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 50,
            multiline: true,
          },
        },
        {
          name: 'childAdoptionHistory.description',
          alias: 'description',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ childAdoptionHistory }) =>
            [InvolvementEnum.myself, InvolvementEnum.partner].includes(childAdoptionHistory?.value),
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 100,
            multiline: true,
          },
        },
        {
          name: 'substanceAbuseHistory.value',
          alias: 'substanceAbuseHistory',
          aliasOption: 'involvement',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          optionVisibleIf: showPartnerOption,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            enum: InvolvementEnum,
          },
        },
        {
          name: 'substanceAbuseHistory.year',
          alias: 'year',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ substanceAbuseHistory }) =>
            [InvolvementEnum.myself, InvolvementEnum.partner].includes(substanceAbuseHistory?.value),
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 50,
            multiline: true,
          },
        },
        {
          name: 'substanceAbuseHistory.description',
          alias: 'description',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ substanceAbuseHistory }) =>
            [InvolvementEnum.myself, InvolvementEnum.partner].includes(substanceAbuseHistory?.value),
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 100,
            multiline: true,
          },
        },
        {
          name: 'domesticViolenceHistory.value',
          alias: 'domesticViolenceHistory',
          aliasOption: 'involvement',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          optionVisibleIf: showPartnerOption,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            enum: InvolvementEnum,
          },
        },
        {
          name: 'domesticViolenceHistory.year',
          alias: 'year',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ domesticViolenceHistory }) =>
            [InvolvementEnum.myself, InvolvementEnum.partner].includes(domesticViolenceHistory?.value),
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 50,
            multiline: true,
          },
        },
        {
          name: 'domesticViolenceHistory.description',
          alias: 'description',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ domesticViolenceHistory }) =>
            [InvolvementEnum.myself, InvolvementEnum.partner].includes(domesticViolenceHistory?.value),
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 100,
            multiline: true,
          },
        },
        {
          name: 'lawsuitHistory.value',
          alias: 'lawsuitHistory',
          aliasOption: 'involvement',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          optionVisibleIf: showPartnerOption,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            enum: InvolvementEnum,
          },
        },
        {
          name: 'lawsuitHistory.year',
          alias: 'year',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ lawsuitHistory }) =>
            [InvolvementEnum.myself, InvolvementEnum.partner].includes(lawsuitHistory?.value),
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 50,
            multiline: true,
          },
        },
        {
          name: 'lawsuitHistory.description',
          alias: 'description',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ lawsuitHistory }) =>
            [InvolvementEnum.myself, InvolvementEnum.partner].includes(lawsuitHistory?.value),
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 100,
            multiline: true,
          },
        },
        {
          name: 'childSupportDelinquency.value',
          alias: 'childSupportDelinquency',
          aliasOption: 'involvement',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          optionVisibleIf: showPartnerOption,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            enum: InvolvementEnum,
          },
        },
        {
          name: 'childSupportDelinquency.year',
          alias: 'year',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ childSupportDelinquency }) =>
            [InvolvementEnum.myself, InvolvementEnum.partner].includes(childSupportDelinquency?.value),
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 50,
            multiline: true,
          },
        },
        {
          name: 'childSupportDelinquency.description',
          alias: 'description',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ childSupportDelinquency }) =>
            [InvolvementEnum.myself, InvolvementEnum.partner].includes(childSupportDelinquency?.value),
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 100,
            multiline: true,
          },
        },
        {
          name: 'custodyLossHistory.value',
          alias: 'custodyLossHistory',
          aliasOption: 'involvement',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          optionVisibleIf: showPartnerOption,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            enum: InvolvementEnum,
          },
        },
        {
          name: 'custodyLossHistory.year',
          alias: 'year',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ custodyLossHistory }) =>
            [InvolvementEnum.myself, InvolvementEnum.partner].includes(custodyLossHistory?.value),
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 50,
            multiline: true,
          },
        },
        {
          name: 'custodyLossHistory.description',
          alias: 'description',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ custodyLossHistory }) =>
            [InvolvementEnum.myself, InvolvementEnum.partner].includes(custodyLossHistory?.value),
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 100,
            multiline: true,
          },
        },
      ],
    },
  ],
};
