import { FC } from 'react';
import { useFormikContext } from 'formik';

import { DynamicFormComponentProps, DynamicFormConfigCommon } from '../types';

export const visibleIf =
  <Props extends DynamicFormComponentProps<DynamicFormConfigCommon>>(Component: FC<Props>) =>
  (props: Props) => {
    const { values } = useFormikContext<Record<any, any>>();

    if (props.config?.visibleIf && !props.config.visibleIf(values, props.config)) {
      return null;
    }

    //TODO: add new field to config dependOn and wrap by useMemo
    return <Component {...props} values={values} />;
  };
