import { memo } from 'react';
import { useUnmount, useUpdateEffect } from 'react-use';
import { useFormikContext } from 'formik';
import { useDebouncedCallback } from 'use-debounce';

export interface AutoSaveProps {
  readonly wait?: number;
  readonly maxWait?: number;
}

export const AutoSave = memo(({ wait = 5000, maxWait = 10000 }: AutoSaveProps) => {
  const { values, submitForm } = useFormikContext();

  const debouncedSubmitForm = useDebouncedCallback(submitForm, wait, {
    maxWait,
  });

  useUpdateEffect(() => {
    debouncedSubmitForm();
  }, [values]);

  useUnmount(() => {
    debouncedSubmitForm.flush();
  });

  return null;
});
