import { memo, useCallback, useMemo } from 'react';
import { MuiTelInput, MuiTelInputProps, MuiTelInputInfo } from 'mui-tel-input';

import { TextLabel } from './TextLabel';

export interface PhoneProps extends MuiTelInputProps {
  readonly description?: string | null;
  readonly length?: number;
  readonly readOnly?: boolean;
}

export const Phone = memo(
  ({ label, required, description, onChange, length = 12, readOnly = false, InputProps, ...props }: PhoneProps) => {
    const handleChange = useCallback((value: string, info: MuiTelInputInfo) => {
      if (onChange) {
        if (!length || !info?.numberValue || info?.numberValue.length <= length) {
          onChange(value, info);
        }
      }
    }, []);

    const InputPropsMemo = useMemo(() => ({ ...InputProps, readOnly }), [readOnly, InputProps]);

    return (
      <MuiTelInput
        fullWidth
        disableDropdown
        forceCallingCode
        InputProps={InputPropsMemo}
        onChange={handleChange}
        label={label && <TextLabel label={label} required={required} description={description} />}
        defaultCountry="US"
        langOfCountryName="en"
        variant="standard"
        sx={{
          '.MuiTelInput-Flag': { width: 24, height: 18, img: { width: 24, height: 18 } },
          '.MuiTypography-root': { color: 'common.black' },
        }}
        {...props}
      />
    );
  },
);
