import { Auth } from '@aws-amplify/auth';

Auth.configure({
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_ID,
});

const cognitoClient = Auth;

export { cognitoClient };
