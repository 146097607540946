import { useRef } from 'react';
import { useUpdateEffect } from 'react-use';
import { useFormikContext } from 'formik';
import scrollToElement from 'scroll-to-element';

const getName = (item: Record<any, any>, name: string = ''): string | false => {
  const [firstKey] = Object.keys(item);
  if (firstKey) {
    const first = item[firstKey];
    const newName = (name ? `${name}.` : '') + firstKey;

    if (Array.isArray(first)) {
      const index = first.findIndex(Boolean);
      return getName(first[index], `${newName}.${index}`);
    }

    return newName;
  }

  return false;
};

export interface ScrollToErrorProps {
  readonly offset?: number;
  readonly align?: 'top' | 'middle' | 'bottom';
  readonly ease?: string;
  readonly duration?: number;
  readonly focus?: boolean;
}

export const ScrollToError = ({
  offset = -200,
  align = 'top',
  ease = 'linear',
  duration = 500,
  focus = true,
}: ScrollToErrorProps) => {
  const { submitCount, errors } = useFormikContext();
  const submitCountRef = useRef(0);

  useUpdateEffect(() => {
    const name = getName(errors);

    if (name && submitCount > 0 && submitCountRef.current !== submitCount) {
      const errorElement: HTMLInputElement | null = document.querySelector(`[name="${name}"]`);

      if (errorElement) {
        scrollToElement(errorElement, {
          offset,
          ease,
          duration,
          align,
        });

        if (focus) {
          errorElement.focus();
        }

        submitCountRef.current = submitCount;
      }
    }
  }, [submitCount, errors]);

  return null;
};
