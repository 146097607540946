export enum DynamicFormElementType {
  text = 'text',
  enumSelect = 'enumSelect',
  enumRadio = 'enumRadio',
  enumCheckbox = 'enumCheckbox',
  booleanRadio = 'booleanRadio',
  date = 'date',
  phone = 'phone',
  autocomplete = 'autocomplete',
  information = 'information',
  toggleButton = 'toggleButton',
  toggleButtonGroup = 'toggleButtonGroup',
  upload = 'upload',
  computedText = 'computedText',
  formattedText = 'formattedText',
  moneyText = 'moneyText',
}

export enum DynamicFormElementWidthMode {
  full = 'full',
  half = 'half',
}

export enum DynamicFormArrayType {
  dependent = 'dependent',
}
