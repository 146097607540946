import { ReactNode } from 'react';
import { Card as MuiCard, CardContent, CardMedia, Box, CardActionArea, CardActions } from '@mui/material';

export interface CardProps {
  readonly children: ReactNode;
  readonly img?: string;
  readonly onClick?: () => void;
  readonly actions?: ReactNode;
  readonly size?: 'small' | 'normal' | 'large';
}

export const Card = ({ children, img, onClick, actions, size = 'normal' }: CardProps) => {
  const cardContent = (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
    >
      {img && (
        <CardMedia
          image={img}
          sx={[
            {
              backgroundPosition: 'center top',
              height: {
                xs: 108,
                sm: 180,
              },
            },
            size === 'large' && {
              height: {
                xs: 140,
                sm: 210,
              },
            },
          ]}
        />
      )}
      <CardContent
        sx={[
          size === 'small' && {
            '&, &:last-child': {
              px: 2,
              py: 3,
            },
          },
          size === 'normal' && {
            '&, &:last-child': {
              px: {
                xs: 2,
                sm: 3,
              },
              py: 3,
            },
          },
          size === 'large' && {
            '&, &:last-child': {
              px: {
                xs: 2,
                sm: 3,
                md: 5,
              },
              py: {
                xs: 3,
                md: 4,
              },
            },
          },
        ]}
      >
        {children}
      </CardContent>
    </Box>
  );

  return (
    <MuiCard elevation={0} sx={{ overflow: 'visible' }}>
      {onClick ? <CardActionArea onClick={onClick}>{cardContent}</CardActionArea> : cardContent}
      {actions && <CardActions>{actions}</CardActions>}
    </MuiCard>
  );
};
