import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import ApartmentIcon from '@mui/icons-material/Apartment';
import usePortal from 'react-useportal';

import { Loader } from '@app/ui/loader';
import { Dropdown, DropdownProps } from '@app/ui/dropdown';

import { useGeneratePdf } from '../../hooks/useDownloadPdf';
import { ApplicationPdf } from '../../constants';

export interface ExportPdfDropdownProps extends DropdownProps {
  readonly applicationId: number;
}
export const ExportPdfDropdown = ({ applicationId, label, ...props }: ExportPdfDropdownProps) => {
  const { t } = useTranslation('common');
  const { generatePdf, isLoading } = useGeneratePdf();
  const { Portal } = usePortal();

  const exportOption = useMemo(
    () => [
      {
        id: 'parents',
        name: (
          <>
            <GroupOutlinedIcon color="secondary" sx={{ mr: 1 }} />
            <span>{t(`application.export.option.${ApplicationPdf.general}`)}</span>
          </>
        ),
        onClick: async () => {
          await generatePdf(applicationId, ApplicationPdf.general);
        },
      },
      {
        id: 'agency',
        name: (
          <>
            <ApartmentIcon color="primary" sx={{ mr: 1 }} />
            <span>{t(`application.export.option.${ApplicationPdf.agency}`)}</span>
          </>
        ),
        onClick: async () => {
          await generatePdf(applicationId, ApplicationPdf.agency);
        },
      },
    ],
    [],
  );

  return (
    <>
      <Portal>
        <Loader isLoading={isLoading} />
      </Portal>
      <Dropdown label={label} options={exportOption} {...props} />
    </>
  );
};
