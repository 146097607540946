import { useRef, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone, FileError, DropzoneOptions } from 'react-dropzone';
import { Box, Grid, SvgIcon, FormControl, FormLabel, FormHelperText } from '@mui/material';

import { TextLabel } from '@app/ui/forms/components/TextLabel';
import { ReactComponent as filesIcon } from '@app/app/images/icons/icon-files.svg';
import { ReactComponent as fileUploadIcon } from '@app/app/images/icons/icon-file-upload.svg';

import { UploadFileItem } from './UploadFileItem';

export interface UploadDropZoneProps extends DropzoneOptions {
  readonly name: string;
  readonly label?: ReactNode;
  readonly description?: ReactNode;
  readonly required?: boolean;
  readonly error?: FileError[];
  readonly value?: Record<any, any>[];
  readonly onChange: (files: File[]) => void;
  readonly onDelete: (file: any) => void;
  readonly onDownload: (file: any) => void;
  readonly disabled?: boolean;
}

export const UploadDropZone = ({
  name,
  label,
  description,
  required,
  error,
  value,
  onChange,
  onDelete,
  onDownload,
  maxFiles,
  disabled,
  ...rest
}: UploadDropZoneProps) => {
  const { t } = useTranslation('common');
  const inputRef = useRef(null);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: onChange, disabled, ...rest });

  const isError = Boolean(error);

  return (
    <FormControl required={required} error={isError} fullWidth>
      <Box sx={{ maxWidth: 740 }}>
        {label && (
          <FormLabel disabled={disabled} required={false}>
            <TextLabel label={label} required={required} description={description} />
          </FormLabel>
        )}
        <input type="file" ref={inputRef} name={name} {...getInputProps()} hidden style={{ visibility: 'hidden' }} />
        {(!maxFiles || !value?.length || value.length < maxFiles) && (
          <Box
            sx={[
              {
                opacity: disabled ? 0.6 : 1,
                bgcolor: isDragActive && !disabled ? 'primary.light' : 'grey.200',
                color: isDragActive && !disabled ? 'primary.secondary' : 'text.primary',
                fontSize: 14,
                fontWeight: 400,
                display: 'flex',
                alignItems: 'center',
                justifyContent: isDragActive && !disabled ? 'center' : 'flex-start',
                border: theme => `1px dashed ${theme.palette.grey[500]}`,
                minHeight: 84,
                px: 3,
                py: 2,
                borderRadius: '8px',
              },
              !disabled && {
                cursor: 'pointer',

                '&:hover': {
                  bgcolor: 'primary.light',
                },
              },
            ]}
            {...getRootProps()}
          >
            {isDragActive ? (
              <Box>{t('general.upload.active')}</Box>
            ) : (
              <>
                <SvgIcon
                  sx={{ color: 'grey.500', fontSize: 38, mr: { xs: 2, sm: 3 } }}
                  component={filesIcon}
                  viewBox="0 0 37 46"
                />
                <Box>
                  <Box>{t('general.upload.title')}</Box>
                  <Box
                    sx={{
                      color: 'primary.main',
                      fontWeight: 600,
                      lineHeight: 1,
                      display: 'flex',
                      alignItems: 'center',
                      mt: 0.75,
                    }}
                  >
                    <SvgIcon sx={{ fontSize: 12, mr: 1 }} component={fileUploadIcon} viewBox="0 0 12 12" />
                    {t('general.button.upload')}
                  </Box>
                </Box>
              </>
            )}
          </Box>
        )}

        {value?.length ? (
          <Box mt={3}>
            <Grid container rowSpacing={{ xs: 2, sm: 1 }} columnSpacing={2}>
              {value.map((file, index) => (
                <Grid key={index} item xs={12} sm={6}>
                  <UploadFileItem
                    disabled={disabled}
                    value={file}
                    onDelete={onDelete}
                    onDownload={onDownload}
                    error={isError}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : null}

        {isError && (
          <Box sx={{ mt: 0.25 }}>
            {error?.map(({ code }) => (
              <FormHelperText error key={code}>
                {t('general.validation.upload', code)}
              </FormHelperText>
            ))}
          </Box>
        )}
      </Box>
    </FormControl>
  );
};
