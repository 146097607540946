import { unflatten } from 'flat';

export const convertArrayToGQL = (arr: string[], autoId: boolean = false) => {
  const fieldMap: Record<any, any> = unflatten(
    arr.reduce((acc, field) => {
      acc[field] = null;

      return acc;
    }, {} as Record<any, any>),
  );

  const indent = '  ';
  const recurse = (map: Record<any, any>, depth: number) => {
    let gql = autoId ? `${indent.repeat(depth + 1)}id\n` : '';
    Object.entries(map).forEach(([field, value]) => {
      gql += indent.repeat(depth + 1) + field;
      if (value) {
        gql += ` {\n${recurse(value, depth + 1)}${indent.repeat(depth + 1)}}\n`;
      } else {
        gql += '\n';
      }
    });

    return gql;
  };

  return recurse(fieldMap, 0);
};
