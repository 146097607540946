import { LinearProgress as MuiLinearProgress, LinearProgressProps as MuiLinearProgressProps, Box } from '@mui/material';

export interface LinearProgressProps extends MuiLinearProgressProps {}

export const LinearProgress = ({ value = 0, variant = 'determinate', sx, ...props }: LinearProgressProps) => {
  return (
    <Box
      sx={[
        {
          display: 'flex',
          alignItems: 'center',
          minWidth: 100,
          width: '100%',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <MuiLinearProgress sx={{ width: '100%' }} value={value} variant={variant} color="secondary" {...props} />
      <Box sx={{ color: 'text.secondary', fontSize: 14, ml: 2 }}>{value}%</Box>
    </Box>
  );
};
