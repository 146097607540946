import { ReactNode, memo } from 'react';
import {
  FormControl,
  FormLabel,
  Radio,
  FormControlLabel,
  FormHelperText,
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
} from '@mui/material';

import { Option } from '../types';
import { TextLabel } from './TextLabel';

export interface RadioGroupProps extends MuiRadioGroupProps {
  readonly options: Option[];
  readonly helperText?: ReactNode;
  readonly label?: ReactNode;
  readonly description?: ReactNode;
  readonly required?: boolean;
  readonly disabled?: boolean;
  readonly error?: boolean;
}

export const RadioGroup = memo(
  ({ options, helperText, label, required, error, disabled, name, row, description, ...props }: RadioGroupProps) => {
    return (
      <FormControl required={required} error={error} disabled={disabled}>
        {label && (
          <FormLabel sx={{ mb: 2 }} required={false}>
            <TextLabel label={label} required={required} description={description} />
          </FormLabel>
        )}

        <MuiRadioGroup row={row} name={name} {...props}>
          {options.map(option => (
            <FormControlLabel
              key={option.id}
              value={option.id}
              control={<Radio />}
              label={option.name}
              labelPlacement="end"
            />
          ))}
        </MuiRadioGroup>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  },
);
