import { RadioGroup, RadioGroupProps } from './RadioGroup';
import { useEnumOptions, UseEnumOptions } from '../../hooks/useEnumOptions';

export type EnumRadioGroupProps = Omit<RadioGroupProps, 'options'> & UseEnumOptions;

export const EnumRadioGroup = ({ enum: data, translation, ...props }: EnumRadioGroupProps) => {
  const options = useEnumOptions({ translation, enum: data });

  return <RadioGroup {...props} options={options} />;
};
