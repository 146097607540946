import { FC, useMemo } from 'react';
import { useFormikContext } from 'formik';

import { pageCopyWithNewIndex } from '../utils/array';
import { DynamicFormComponentProps, DynamicFormConfigPage } from '../types';

export const array =
  <Props extends DynamicFormComponentProps<DynamicFormConfigPage>>(Component: FC<Props>) =>
  (props: Props) => {
    const { values } = useFormikContext<Record<any, any>>();

    const length = props.config.array?.length(values);

    const content = useMemo(
      () =>
        new Array(length).fill(0).map((value, index) => {
          const currentConfig = pageCopyWithNewIndex(props.config, index);

          return <Component key={index} {...props} config={currentConfig} />;
        }),
      [length],
    );

    return <>{content}</>;
  };
