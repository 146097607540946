import { gql } from '@apollo/client';

export const FILE_CREATE_ONE_WITH_PRE_SIGNED_POST = gql`
  mutation fileCreateOneWithPreSignedPost($input: FileCreateInput!) {
    fileCreateOneWithPreSignedPost(input: $input) {
      file {
        id
        name
      }
      signedPost {
        fields
        url
      }
    }
  }
`;

export const FILE_DOWNLOAD_ONE = gql`
  query fileDownloadOne($id: Int!) {
    fileDownloadOne(id: $id) {
      url
    }
  }
`;

export const FILE_DELETE_ONE = gql`
  mutation fileDeleteOne($id: Int!) {
    fileDeleteOne(id: $id) {
      id
    }
  }
`;
