import { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, FormikProps, withFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { AutoSave } from '@app/formik';

import { pageTransformArrays, surveyTransformArrays } from '../../utils/array';
import { isVisible } from '../../utils/visibleIf';
import { isPageCompleted } from '../../utils/progress';
import { DynamicFormProps } from '../DynamicForm';
import { DynamicFormPageExtended } from '../DynamicFormPage';
import { DynamicFormWizardFinish } from './DynamicFormWizardFinish';

const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

export interface DynamicFormWizardProps extends DynamicFormProps {
  readonly onCompleted: () => void;
  readonly opened?: boolean;
}
export const DynamicFormWizard = withFormik<DynamicFormWizardProps, Record<any, any>>({
  mapPropsToValues: props => props.initialValues,
  handleSubmit: (values, { props, ...helpers }) => {
    if (props.onSubmit) {
      props.onSubmit(values, helpers);
    }
  },
  displayName: 'DynamicFormWizard',
})(
  ({
    translation,
    config,
    values,
    onCompleted,
    readOnly,
    disabled,
    opened = false,
  }: DynamicFormWizardProps & FormikProps<Record<any, any>>) => {
    const { t } = useTranslation('common');

    const [isFinishedStep, setIsFinishedStep] = useState(false);

    const isSurveyShouldRebuild = config.array?.length(values);

    const surveyConfig = useMemo(() => surveyTransformArrays(config, values), [isSurveyShouldRebuild]);

    const currentTranslation = surveyConfig?.translation ?? translation;

    const pages = useMemo(
      () => pageTransformArrays(surveyConfig.pages, values).filter(page => isVisible(page, values)),
      [values],
    );

    const findNextStepIndex = () => {
      return pages.findIndex(page => !isPageCompleted(page, values));
    };

    const initialActiveStep = useMemo(() => {
      const nextIndex = findNextStepIndex();
      const firstNotInformationPage = pages.findIndex(page => !page.information);

      return nextIndex > firstNotInformationPage && opened ? nextIndex : 0;
    }, []);

    const [activeStep, setActiveStep] = useState(initialActiveStep);

    const { pageNumber, pageTotal } = useMemo(
      () =>
        pages.reduce(
          (acc, page, index) => {
            if (!page.information) {
              acc.pageTotal += 1;

              if (index === activeStep) {
                acc.pageNumber = acc.pageTotal;
              }
            }

            return acc;
          },
          { pageNumber: 0, pageTotal: 0 } as { pageNumber: number; pageTotal: number },
        ),
      [pages.length, activeStep],
    );

    const currentPage = pages[activeStep];
    const isLastStep = activeStep === pages.length - 1;

    const isCompletedCurrentStep = currentPage ? isPageCompleted(currentPage, values) : true;

    const handleSkip = useCallback(() => {
      setActiveStep(prevStep => prevStep + 1);
    }, []);

    const handleBack = useCallback(() => {
      setActiveStep(prevStep => prevStep - 1);
    }, []);

    const handleNext = () => {
      if (isLastStep) {
        const nextIndex = findNextStepIndex();

        if (nextIndex >= 0) {
          setActiveStep(nextIndex);
        } else {
          setIsFinishedStep(true);
        }
      } else {
        handleSkip();
      }
    };

    useEffect(() => {
      scrollToTop();
    }, [activeStep]);

    if (!currentPage) {
      return <></>;
    }

    return (
      <>
        {isFinishedStep ? (
          <DynamicFormWizardFinish onClick={onCompleted} survey={config.name} data={values} steps={pageTotal} />
        ) : (
          <Form noValidate autoComplete="off">
            <Box>
              {activeStep > 0 && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: { xs: 2, md: 4 } }}>
                  <Button
                    onClick={handleBack}
                    color="primary"
                    startIcon={<NavigateBeforeIcon />}
                    sx={{ visibility: activeStep > 0 ? 'initial' : 'hidden' }}
                  >
                    {t('general.button.back')}
                  </Button>
                  {!!pageNumber && (
                    <Typography color="text.secondary">
                      {t('application.wizard.question')}&nbsp;{pageNumber}/{pageTotal}
                    </Typography>
                  )}
                  <Button
                    onClick={handleSkip}
                    color="primary"
                    endIcon={<NavigateNextIcon />}
                    sx={{ visibility: isLastStep || !pageNumber ? 'hidden' : 'initial' }}
                  >
                    {t('general.button.skip')}
                  </Button>
                </Box>
              )}

              <DynamicFormPageExtended
                isWizard
                readOnly={readOnly}
                disabled={disabled}
                key={activeStep}
                config={currentPage}
                translation={currentTranslation}
                values={values}
              />

              <Box
                sx={{
                  display: { sm: 'flex' },
                  justifyContent: 'flex-end',
                  mt: { xs: 3, sm: 4 },
                }}
              >
                <LoadingButton
                  sx={{
                    width: {
                      xs: '100%',
                      sm: 'auto',
                    },
                    minWidth: 120,
                  }}
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  disabled={!isCompletedCurrentStep}
                >
                  {activeStep === 0
                    ? t('general.button.letsGo')
                    : isLastStep
                    ? t('general.button.finish')
                    : t('general.button.next')}
                </LoadingButton>
              </Box>
            </Box>

            <AutoSave wait={10000} maxWait={60000} />
          </Form>
        )}
      </>
    );
  },
);
