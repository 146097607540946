import { Route, Switch, Redirect } from 'react-router-dom';

import { useAuth, useAuthSignOutSubscriber } from '@app/auth';
import { Loader } from '@app/ui/loader';
import { MainLayout } from '@app/layouts';
import { useCardImagePreload } from '@app/application/hooks/useCardImagePreload';

import { SurrogateApplicationPage } from './surrogate-application';
import { UserFormPage } from './surrogate-application/user-form';
import { UploadPhotosPage } from './surrogate-application/upload-photos';
import { AdminUsersPage } from './admin/users';
import { AdminSingleUserPage } from './admin/single-user';
import { AdminSingleUserFormPage } from './admin/single-user-form';
import { ProfilePage } from './profile';

export const Private = () => {
  const { user, isAdmin } = useAuth();

  useAuthSignOutSubscriber();
  useCardImagePreload();

  return user ? (
    <>
      <MainLayout>
        {isAdmin ? (
          <Switch>
            <Route exact path="/admin/users" component={AdminUsersPage} />
            <Route exact path="/admin/users/:id" component={AdminSingleUserPage} />
            <Route exact path="/admin/users/:id/:survey" component={AdminSingleUserFormPage} />
            <Route exact path="/my-account" component={ProfilePage} />
            <Redirect to="/admin/users" />
          </Switch>
        ) : (
          <Switch>
            <Route exact path="/surrogate-application" component={SurrogateApplicationPage} />
            <Route exact path="/surrogate-application/upload-photos" component={UploadPhotosPage} />
            <Route exact path="/surrogate-application/:survey" component={UserFormPage} />
            <Route exact path="/my-account" component={ProfilePage} />
            <Redirect to="/surrogate-application" />
          </Switch>
        )}
      </MainLayout>
    </>
  ) : (
    <Loader isLoading />
  );
};
