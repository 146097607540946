import { ReactNode } from 'react';
import { Button, Dialog, DialogProps, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { ModalTransition } from './ModalTransition';

export interface ModalProps extends Omit<DialogProps, 'title' | 'onClose' | 'onSubmit'> {
  readonly onClose: (reason: 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick' | 'submitClick') => void;
  readonly title?: ReactNode;
  readonly actions?: ReactNode;
}

export const Modal = ({
  open,
  maxWidth = 'md',
  fullWidth = true,
  fullScreen,
  scroll = 'body',
  onClose,
  children,
  title,
  actions,
}: ModalProps) => {
  return (
    <Dialog
      open={open}
      onClose={() => onClose('backdropClick')}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      scroll={scroll}
      fullWidth={fullWidth}
      TransitionComponent={ModalTransition}
    >
      <Button
        onClick={() => onClose('closeButtonClick')}
        sx={{
          fontSize: 18,
          color: 'text.secondary',
          position: 'absolute',
          right: 18,
          top: 18,
          p: 0.5,
          width: 20,
          minWidth: 20,
          height: 20,
          borderRadius: '50%',
        }}
      >
        <CloseIcon fontSize="inherit" />
      </Button>

      {title && (
        <DialogTitle variant="h2" component="h3">
          {title}
        </DialogTitle>
      )}

      <DialogContent>{children}</DialogContent>

      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};
