import { Route, Switch } from 'react-router-dom';

import { useSignedIn } from '@app/auth';
import { useScrollToTop } from '@app/app/hooks/useScrollToTop';
import { useGoogleTagManager } from '@app/app/hooks/useTagManager';

import { Public } from './_public';
import { Private } from './_private';
import { ChangeEmailConfirmPage } from './change-email-confirm';
import { PasswordCreatePage } from './password-create';
import { AdminSingleUserPdfPage } from './admin/single-user-pdf';
import { SignUpPage } from './sign-up';

export const App = () => {
  const isSignedIn = useSignedIn();
  useScrollToTop();
  useGoogleTagManager();

  return (
    <Switch>
      <Route exact path="/sign-up" component={SignUpPage} />
      <Route exact path="/change-email/:token" component={ChangeEmailConfirmPage} />
      <Route exact path="/password-create/:token" component={PasswordCreatePage} />
      <Route exact path="/admin/users/:id/pdf/:type" component={AdminSingleUserPdfPage} />
      <Route path="/" component={isSignedIn ? Private : Public} />
    </Switch>
  );
};
