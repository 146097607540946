import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ModalProvider } from 'react-modal-hook';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import '@app/language/configs/i18n';
import { theme } from '@app/app/configs/theme';
import { apolloClient } from '@app/query/configs/appoloClient';
import { AuthProvider } from '@app/auth/components/AuthProvider';
import { SnackbarsProvider } from '@app/snackbars/components/SnackbarsProvider';

import { App } from './pages/_app';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <ThemeProvider theme={theme}>
    <ApolloProvider client={apolloClient}>
      <SnackbarsProvider>
        <AuthProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ModalProvider>
              <BrowserRouter>
                <App />
                <CssBaseline />
              </BrowserRouter>
            </ModalProvider>
          </LocalizationProvider>
        </AuthProvider>
      </SnackbarsProvider>
    </ApolloProvider>
  </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
