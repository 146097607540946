import { CheckboxGroup, CheckboxGroupProps } from '@app/formik';

import { useEnumOptions, UseEnumOptions } from '../../hooks/useEnumOptions';

export type EnumCheckboxGroupProps = Omit<CheckboxGroupProps, 'options'> & UseEnumOptions;

export const EnumCheckboxGroup = ({ enum: data, translation, ...props }: EnumCheckboxGroupProps) => {
  const options = useEnumOptions({ translation, enum: data });

  return <CheckboxGroup {...props} options={options} />;
};
