import { ReactNode } from 'react';
import { Box } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

export interface LabelProps {
  readonly text: ReactNode;
  readonly icon?: ReactNode;
  readonly rounded?: boolean;
  readonly color?: 'grey' | 'info' | 'secondary' | 'error' | 'success' | 'warning' | 'disabled' | string;
  readonly sx?: SxProps<Theme>;
}

export const Label = ({ text, icon, rounded, color = 'info', sx }: LabelProps) => {
  return (
    <Box
      sx={[
        {
          fontSize: 14,
          fontWeight: 600,
          p: '3px 15px',
          borderRadius: rounded ? '100px' : '8px',
          display: 'inline-flex',
          alignItems: 'center',
          whiteSpace: 'nowrap',
        },
        color === 'grey' && {
          color: 'grey.500',
          bgcolor: 'grey.300',
        },
        color === 'info' && {
          color: 'info.main',
          bgcolor: 'info.light',
        },
        color === 'secondary' && {
          color: 'secondary.main',
          bgcolor: 'secondary.light',
        },
        color === 'error' && {
          color: 'error.main',
          bgcolor: 'error.light',
        },
        color === 'success' && {
          color: 'success.main',
          bgcolor: 'success.light',
        },
        color === 'warning' && {
          color: 'warning.main',
          bgcolor: 'warning.light',
        },
        color === 'disabled' && {
          color: 'text.disabled',
          bgcolor: 'grey.100',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {icon && (
        <Box
          sx={{ fontSize: '1.3em', mr: 0.75, display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}
        >
          {icon}
        </Box>
      )}
      {text}
    </Box>
  );
};
