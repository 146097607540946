import { ElementType, useMemo } from 'react';
import { paramCase } from 'change-case';
import { useTranslation } from 'react-i18next';
import { Box, Button, ButtonBase, Grid, IconButton, IconButtonProps, Paper, SvgIcon, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { Label } from '@app/ui/label';
import { LinearProgress } from '@app/ui/progress';
import { Tooltip } from '@app/ui/tooltip';
import { useIsMobile } from '@app/app/hooks/useIsMobile';
import { calculateSurveyProgress, isVisible } from '@app/dynamic/utils';
import { ReactComponent as docTimeIcon } from '@app/app/images/icons/icon-doc-time.svg';
import { ReactComponent as updateIcon } from '@app/app/images/icons/icon-update.svg';
import { ReactComponent as checkIcon } from '@app/app/images/icons/icon-check.svg';
import { ReactComponent as playIcon } from '@app/app/images/icons/icon-play.svg';

import { applicationConfig } from '../../configs';
import { Application } from '../../types/Application';
import { ApplicationProgressStatusEnum, ApplicationStatusEnum } from '../../constants';

const gatProgressStatusConfig = (
  progress: number,
  max: number,
): {
  color: IconButtonProps['color'];
  status: ApplicationProgressStatusEnum;
  icon: ElementType;
  iconButton: ElementType;
} => {
  if (progress === 0) {
    return {
      status: ApplicationProgressStatusEnum.notStarted,
      color: 'secondary',
      icon: docTimeIcon,
      iconButton: playIcon,
    };
  }

  if (progress === max) {
    return {
      status: ApplicationProgressStatusEnum.completed,
      color: 'success',
      icon: checkIcon,
      iconButton: checkIcon,
    };
  }

  return {
    status: ApplicationProgressStatusEnum.inProgress,
    color: 'warning',
    icon: updateIcon,
    iconButton: updateIcon,
  };
};

export interface ApplicationListProps {
  readonly item: Application;
  readonly onClick: (survey: string) => void;
  readonly onSubmit?: () => void;
  readonly isAdmin?: boolean;
}

export const ApplicationList = ({ onClick, item, isAdmin, onSubmit }: ApplicationListProps) => {
  const { t } = useTranslation('common');
  const isMobile = useIsMobile();

  const applicationProgress = useMemo(() => calculateSurveyProgress(applicationConfig.surveys, item), [item]);

  const surveysConfig = useMemo(() => applicationConfig.surveys.filter(survey => isVisible(survey, item)), [item]);

  const notFinished = applicationProgress.progressTotal !== 100;

  const isSubmittedSurrogate = item.status !== ApplicationStatusEnum.applicants && !isAdmin;

  return (
    <>
      <Box
        sx={{
          display: {
            sm: 'flex',
          },
          alignItems: 'center',
          mb: { xs: 3, md: 4 },
        }}
      >
        <Typography
          variant={isMobile ? 'h2' : 'h3'}
          component="h3"
          sx={{
            whiteSpace: 'nowrap',
            mr: {
              sm: 3,
              md: 5,
            },
            mb: {
              xs: 1,
              sm: 0,
            },
          }}
        >
          {t('user.page.single.currentProgress')}
        </Typography>
        <LinearProgress value={applicationProgress.progressTotal} />
      </Box>
      <Paper
        sx={{
          borderRadius: '20px',
        }}
        variant="outlined"
        elevation={0}
      >
        {surveysConfig.map(survey => {
          const progressConfig = applicationProgress.populatedSurveyCountMap[survey.name];

          const { status, icon, iconButton, color } = gatProgressStatusConfig(
            progressConfig.populatedPageCount,
            progressConfig.pageCount,
          );
          const handleSurvey = () => onClick(paramCase(survey.name));

          return (
            <ButtonBase
              className="list-item"
              key={survey.name}
              onClick={handleSurvey}
              component="div"
              sx={{
                borderTop: theme => `1px solid ${theme.palette.grey[500]}`,
                fontSize: { xs: 14, md: 16 },
                position: 'relative',
                display: 'block',
                pl: {
                  xs: 2,
                  sm: 3,
                  lg: 5,
                },
                pr: {
                  xs: 6,
                  lg: 5,
                },
                py: {
                  xs: 3,
                  lg: 4,
                },
                '&:first-of-type': {
                  borderTop: 'none',
                  borderTopLeftRadius: '20px',
                  borderTopRightRadius: '20px',
                },
                '&:last-of-type': {
                  borderBottomLeftRadius: '20px',
                  borderBottomRightRadius: '20px',
                },
                '&:hover': {
                  boxShadow: {
                    md: '0px 5px 25px rgba(0, 0, 0, 0.15)',
                  },
                },
              }}
            >
              <Grid container spacing={{ xs: 1, md: 2 }} alignItems="center">
                <Grid item xs={12} sm={7} lg={8}>
                  <Typography variant={isMobile ? 'h4' : 'h3'} component="h3">
                    {t(`application.field.survey.option.${survey.name}.name`)}
                  </Typography>
                </Grid>

                <Grid item xs="auto" sm>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: { xs: 11, md: 14 },
                      pr: { xs: 1, sm: 2, md: 0 },
                      minWidth: 70,
                    }}
                  >
                    <SvgIcon
                      component={docTimeIcon}
                      viewBox="0 0 24 24"
                      sx={{
                        color: isSubmittedSurrogate ? 'text.secondary' : `${color}.main`,
                        fontSize: { xs: 18, md: 24 },
                        mr: { xs: 0.5, md: 1.5 },
                      }}
                    />
                    {progressConfig.populatedPageCount}/{progressConfig.pageCount}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs="auto"
                  sm
                  sx={[
                    !isAdmin && {
                      display: {
                        xs: 'none',
                        sm: 'block',
                      },
                    },
                  ]}
                >
                  <Label
                    icon={<SvgIcon component={icon} viewBox="0 0 24 24" fontSize="inherit" />}
                    text={t(`application.form.status.${status}`)}
                    color={isSubmittedSurrogate ? 'disabled' : color}
                    sx={{
                      fontSize: { xs: 11, md: 14 },
                      fontWeight: 400,
                    }}
                  />
                </Grid>
                <Grid item xs="auto">
                  <IconButton
                    className="filledTonal"
                    sx={{
                      p: 1,
                      opacity: {
                        md: isSubmittedSurrogate ? 1 : 0,
                      },
                      '.list-item:hover &': {
                        opacity: 1,
                      },
                      position: {
                        xs: 'absolute',
                        md: 'relative',
                      },
                      top: {
                        xs: '50%',
                        md: 'auto',
                      },
                      right: {
                        xs: isAdmin ? 8 : 20,
                        sm: 16,
                        md: 'auto',
                      },
                      mt: {
                        xs: -2,
                        md: 0,
                      },
                    }}
                    color={isAdmin ? 'secondary' : color}
                    size="small"
                    onClick={handleSurvey}
                    disabled={isSubmittedSurrogate}
                  >
                    {isAdmin ? (
                      <EditOutlinedIcon />
                    ) : (
                      <SvgIcon component={iconButton} viewBox="0 0 24 24" fontSize="inherit" />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            </ButtonBase>
          );
        })}
      </Paper>

      {!isAdmin && item.status === ApplicationStatusEnum.applicants && (
        <Box
          sx={{
            display: { sm: 'flex' },
            justifyContent: 'flex-end',
            borderTop: theme => `1px solid ${theme.palette.grey[500]}`,
            pt: { xs: 3, sm: 4 },
            mt: { xs: 3, sm: 4 },
          }}
        >
          <Tooltip
            sx={{ display: 'block' }}
            title={notFinished ? t('application.button.finishAndSubmit.description') : null}
          >
            <div>
              <Button
                sx={{
                  width: {
                    xs: '100%',
                    sm: 'auto',
                  },
                }}
                variant="contained"
                color="primary"
                onClick={onSubmit}
                disabled={notFinished}
              >
                {t('application.button.finishAndSubmit.name')}
              </Button>
            </div>
          </Tooltip>
        </Box>
      )}
    </>
  );
};
