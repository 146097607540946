import { useModal } from 'react-modal-hook';
import { useCallback, useState } from 'react';

import { ConfirmationModal, ConfirmationModalProps } from '../components/ConfirmationModal';

export interface UseConfirmation extends Partial<Omit<ConfirmationModalProps, 'onClose' | 'open'>> {}

export const useConfirmation = ({
  onSubmit = () => {},
  title,
  description,
  cancelButtonText,
  submitButtonText,
  isLoading,
}: UseConfirmation) => {
  const [props, setProps] = useState<Partial<UseConfirmation>>({});

  const [showModal, hideModal] = useModal(
    () => (
      <ConfirmationModal
        open
        title={title}
        description={description}
        cancelButtonText={cancelButtonText}
        submitButtonText={submitButtonText}
        isLoading={isLoading}
        onSubmit={onSubmit}
        {...props}
        onClose={hideModal}
      />
    ),
    [title, description, cancelButtonText, submitButtonText, isLoading, onSubmit, props],
  );

  const handleShowModal = useCallback((newProps?: Partial<UseConfirmation>) => {
    if (newProps) {
      setProps(newProps);
    }
    showModal();
  }, []);

  return [handleShowModal, hideModal];
};
