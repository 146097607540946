import { ReactNode } from 'react';
import { Typography, Box, Button } from '@mui/material';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

export interface SignUpInfoProps {
  readonly children: ReactNode;
  readonly onClick?: () => void;
  readonly iconVariant?: 'positive' | 'negative' | undefined;
  readonly title?: ReactNode;
  readonly buttonText?: ReactNode;
}
export const SignUpInfo = ({ children, title, onClick, buttonText = 'Submit', iconVariant }: SignUpInfoProps) => {
  return (
    <>
      {iconVariant && (
        <Box
          sx={[
            {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              height: 80,
              width: 80,
              fontSize: 48,
              p: 2,
              mb: 4,
            },
            iconVariant === 'positive' && {
              bgcolor: 'success.light',
              color: 'success.main',
            },
            iconVariant === 'negative' && {
              bgcolor: 'error.light',
              color: 'error.main',
            },
          ]}
        >
          {iconVariant === 'positive' && <SentimentVerySatisfiedIcon color="inherit" fontSize="inherit" />}
          {iconVariant === 'negative' && <SentimentVeryDissatisfiedIcon color="inherit" fontSize="inherit" />}
        </Box>
      )}

      {title && (
        <Typography variant="h1" gutterBottom>
          {title}
        </Typography>
      )}

      {children}

      {onClick && (
        <Box display="flex" justifyContent="flex-end" sx={{ mt: 4 }}>
          <Button
            sx={{
              width: {
                xs: '100%',
                sm: 'auto',
              },
            }}
            variant="contained"
            color="primary"
            onClick={onClick}
          >
            {buttonText}
          </Button>
        </Box>
      )}
    </>
  );
};
