import { DynamicFormConfigSurvey } from '@app/dynamic/types';
import { DynamicFormElementType } from '@app/dynamic/constants';

import { ApplicationPdf } from '../constants';
import { applicationConfig } from '../configs';

export const getFieldsFromSurveys = (surveys: DynamicFormConfigSurvey[]) => {
  return surveys.reduce(
    (acc, survey) => {
      survey.pages.forEach(page => {
        page.elements.forEach(element => {
          if (element.type !== DynamicFormElementType.information) {
            const name =
              survey?.meta?.json || page?.meta?.json || element?.meta?.json
                ? (element.name.split('.').shift() as string)
                : element.name;

            acc.surveyFields.push(name);

            if (element.props?.disabled) {
              acc.excludedFields.push(name);
            }
          }
        });
      });

      if (survey.meta?.dependOnFields && Array.isArray(survey.meta.dependOnFields)) {
        acc.surveyFields.push(...survey.meta.dependOnFields);
      }

      return acc;
    },
    //TODO: refactor
    {
      excludedFields: [
        '__typename',
        'user.id',
        'user.name',
        'avatar.__typename',
        'user.__typename',
        'partner.__typename',
        'status',
        'isPhotosSubmitted',
        'isAdmin',
      ],
      surveyFields: [],
    } as {
      excludedFields: string[];
      surveyFields: string[];
    },
  );
};

export const getPdfFields = (surveys: DynamicFormConfigSurvey[], type: ApplicationPdf) => {
  return [
    ...surveys.reduce((acc, survey) => {
      survey.pages.forEach(page => {
        page.elements.forEach(element => {
          if (element?.meta?.pdf && element.meta.pdf[type]) {
            const name =
              survey?.meta?.json || page?.meta?.json || element?.meta?.json
                ? (element.name.split('.').shift() as string)
                : element.name;

            acc.add(name);
          }
        });
      });

      return acc;
    }, new Set(applicationConfig.pdf.coverPageFields)),
  ] as string[];
};

export const prepareAdminConfig = (config: DynamicFormConfigSurvey) => {
  return { ...config, pages: config.pages.filter(page => !page?.information) };
};
