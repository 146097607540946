import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { Button, Box, Typography, Grid } from '@mui/material';

import { Modal } from '@app/ui/modal';
import { Text, yup, MoneyText, ScrollToError } from '@app/formik';
import { success } from '@app/snackbars';
import { Loader } from '@app/ui/loader';
import { DynamicFormConfigSurvey } from '@app/dynamic';

import { useUpdateApplication } from '../../hooks/useUpdateApplication';

const validationSchema = yup.object().shape({
  pdfTitle: yup.string().nullable(),
  baseFee: yup.string().nullable(),
  deposit: yup.string().nullable(),
  travel: yup.string().nullable(),
  restrictions: yup.string().nullable(),
  insurance: yup.string().nullable(),
  lostWages: yup.string().nullable(),
  about: yup.string().nullable(),
  additionalNotes: yup.string().nullable(),
});

const adminPdfSurveyConfig: DynamicFormConfigSurvey = {
  name: 'coverPdfPage',
  meta: {
    dependOnFields: [
      'pdfTitle',
      'baseFee',
      'deposit',
      'travel',
      'restrictions',
      'insurance',
      'lostWages',
      'about',
      'additionalNotes',
    ],
  },
  pages: [],
};

export interface UpdatePdfCoverModalProps {
  readonly id: number;
  readonly open: boolean;
  readonly onClose: () => void;
}

export const UpdatePdfCoverModal = ({ id, open, onClose }: UpdatePdfCoverModalProps) => {
  const { t } = useTranslation('common');

  const { initialValues, isUpdateApplicationLoading, isGetApplicationLoading, onSubmit } = useUpdateApplication({
    id,
    isAdmin: true,
    config: adminPdfSurveyConfig,
    onUpdateSuccess: () => {
      onClose();
      success(t('application.pdf.modal.successMessage'));
    },
  });

  return (
    <Modal open={open} onClose={onClose} title={t('application.pdf.modal.title')}>
      <Loader isLoading={isGetApplicationLoading} />

      <Typography paragraph color="textSecondary">
        {t('application.pdf.modal.subtitle')}
      </Typography>

      {initialValues && (
        <Formik
          enableReinitialize
          onSubmit={onSubmit}
          initialValues={initialValues as Record<any, any>}
          validationSchema={validationSchema}
        >
          {() => (
            <Form noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Text label={t('application.field.pdfTitle.label')} name="pdfTitle" length={60} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MoneyText name="baseFee" label={t('application.field.baseFee.label')} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MoneyText name="deposit" label={t('application.field.deposit.label')} />
                </Grid>
                <Grid item xs={12}>
                  <Text label={t('application.field.travel.label')} name="travel" multiline length={100} />
                </Grid>
                <Grid item xs={12}>
                  <Text label={t('application.field.restrictions.label')} name="restrictions" multiline length={100} />
                </Grid>
                <Grid item xs={12}>
                  <Text label={t('application.field.insurance.label')} name="insurance" multiline length={100} />
                </Grid>
                <Grid item xs={12}>
                  <Text label={t('application.field.lostWages.label')} name="lostWages" multiline length={100} />
                </Grid>
                <Grid item xs={12}>
                  <Text label={t('application.field.about.label')} name="about" multiline length={750} />
                </Grid>
                <Grid item xs={12}>
                  <Text
                    multiline
                    label={t('application.field.additionalNotes.label')}
                    name="additionalNotes"
                    length={750}
                  />
                </Grid>
              </Grid>

              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', pt: 3 }}>
                <Button color="primary" onClick={onClose}>
                  {t('general.button.cancel')}
                </Button>

                <LoadingButton loading={isUpdateApplicationLoading} color="primary" variant="contained" type="submit">
                  {t('general.button.save')}
                </LoadingButton>
              </Box>

              <ScrollToError />
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};
