import { useField } from 'formik';
import { useMemo, useCallback, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import {
  RadioGroupProps as MuiRadioGroupProps,
  RadioGroup as MuiRadioGroup,
} from '@app/ui/forms/components/RadioGroup';

import { useErrorTranslations } from '../../hooks/useErrorTranslations';

export interface BooleanRadioProps extends Omit<MuiRadioGroupProps, 'options'> {
  readonly name: string;
  readonly translation?: string;
}

export const BooleanRadio = ({ name, helperText, translation, ...props }: BooleanRadioProps) => {
  const { t } = useTranslation('common');

  const [{ value }, { touched, error }, { setValue }] = useField(name);

  const options = useMemo(() => {
    const trans = translation ?? 'general.option';

    return [
      {
        id: 'true',
        name: t(`${trans}.yes`, t('general.option.yes')),
      },
      {
        id: 'false',
        name: t(`${trans}.no`, t('general.option.no')),
      },
    ];
  }, [t, translation]);

  const errorHelperText = useErrorTranslations(name, error);

  const isError = Boolean(touched && errorHelperText);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>, newValue: string) => {
    setValue(newValue === 'true');
  }, []);

  return (
    <MuiRadioGroup
      name={name}
      {...props}
      value={String(value)}
      onChange={handleChange}
      options={options}
      error={isError}
      helperText={isError ? errorHelperText : helperText || ''}
    />
  );
};
