import { gql } from '@apollo/client';

import { convertArrayToGQL } from '@app/query/utils/graphql';

export const applicationGetManyWithPagination = (fields: string[]) => gql`
  query applicationGetManyWithPagination(
    $page: Int!
    $limit: Int!
    $orderBy: [OrderByDto]
    $search: String
    $status: ApplicationStatusEnum
    $createdAtLte: String
    $createdAtGte: String  
  ) {
    applicationGetManyWithPagination(
      page: $page
      limit: $limit
      orderBy: $orderBy
      status: $status
      search: $search
      createdAtLte: $createdAtLte
      createdAtGte: $createdAtGte
    ) {
      pagination {
        totalItems
      }
      rows {
          ${convertArrayToGQL(fields, true)}
      }
    }
  }
`;

export const applicationGetOne = (fields: string[]) => {
  return gql`
    query applicationGetOne($id: Int!) {
      applicationGetOne(id: $id) {
        ${convertArrayToGQL(fields, true)}
      }
    }
  `;
};

export const applicationUpdateOne = (fields: string[]) => {
  return gql`
    mutation applicationUpdateOne($input: ApplicationUpdateInput!) {
      applicationUpdateOne(input: $input) {
        ${convertArrayToGQL(fields, true)}
      }
    }
  `;
};

export const APPLICATION_SUBMIT = gql`
  mutation applicationSubmit($input: IdInput!) {
    applicationSubmit(input: $input) {
      id
      status
    }
  }
`;

export const APPLICATION_SUBMIT_PHOTOS = gql`
  mutation applicationSubmitPhotos($input: IdInput!) {
    applicationSubmitPhotos(input: $input) {
      id
      isPhotosSubmitted
    }
  }
`;

export const APPLICATION_UPDATE_ATTACH_PHOTOS = gql`
  mutation applicationUpdateOneAttachPhotos($input: ApplicationUpdateAttachPhotosInput!) {
    applicationUpdateOneAttachPhotos(input: $input) {
      id
      photos {
        id
        name
      }
    }
  }
`;

export const APPLICATION_UPDATE_SURVEY_FLAG = gql`
  mutation applicationUpdateOneSurveyFlag($input: ApplicationUpdateSurveyFlagInput!) {
    applicationUpdateOneSurveyFlag(input: $input) {
      id
      flags
    }
  }
`;
