import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from '@app/ui/forms/types';

export interface UseEnumOptions {
  readonly enum: Record<any, any>;
  readonly translation: string;
}

export const useEnumOptions = ({ enum: data, translation }: UseEnumOptions) => {
  const { t } = useTranslation('common');

  return useMemo(
    () =>
      Object.entries(data).reduce((acc, [index, id]) => {
        if (isNaN(Number(index))) {
          acc.push({ id, name: `${t(`${translation}.${id}`)}` });
        }

        return acc;
      }, [] as Option[]),
    [t],
  );
};
