import { useField } from 'formik';
import { useCallback } from 'react';
import { MuiTelInputInfo } from 'mui-tel-input';

import { Phone as MuiPhone, PhoneProps as MuiPhoneProps } from '@app/ui/forms/components/Phone';

import { useErrorTranslations } from '../../hooks/useErrorTranslations';

export interface PhoneProps extends Omit<MuiPhoneProps, 'value' | 'onChange'> {
  readonly name: string;
}

export const Phone = ({ name, helperText, ...props }: PhoneProps) => {
  const [{ value }, { touched, error }, { setValue }] = useField(name);

  const errorHelperText = useErrorTranslations(name, error);

  const isError = Boolean(touched && errorHelperText);

  const handleChange = useCallback((newValue: string, info: MuiTelInputInfo) => {
    setValue(info.numberValue);
  }, []);

  return (
    <MuiPhone
      {...props}
      value={value || ''}
      name={name}
      onChange={handleChange}
      error={isError}
      helperText={isError ? errorHelperText : helperText || ''}
    />
  );
};
