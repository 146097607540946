import { ApolloError } from '@apollo/client/errors';

export const extractValidationErrors = (error: ApolloError): Record<string, string> =>
  error.graphQLErrors.reduce((acc, currValue) => {
    const res = { ...acc };

    if (currValue.extensions.code === 'BAD_USER_INPUT') {
      // @ts-ignore
      (currValue.extensions.response as any)?.message?.forEach(({ field, message }) => {
        // @ts-ignore
        res[field] = message;
      });
    }

    return res;
  }, {});
