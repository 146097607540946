import { ApplicationDynamicFormConfig } from '../types';

import { personalInfo } from './personalInfo';
import { partnerInfo } from './partnerInfo';
import { personalHistory } from './personalHistory';
import { employmentQuestions } from './employmentQuestions';
import { healthInfo } from './healthInfo';
import { aboutYou } from './aboutYou';
import { pregnancyHistory } from './pregnancyHistory';
import { deliveries } from './deliveries';
import { surrogacyJourney } from './surrogacyJourney';
import { signatureDisclosure } from './signatureDisclosure';

export const applicationConfig: ApplicationDynamicFormConfig = {
  pdf: {
    coverPageFields: [
      'age',
      'languages',
      'baseFee',
      'deposit',
      'travel',
      'restrictions',
      'insurance',
      'lostWages',
      'about',
      'additionalNotes',
      'pdfTitle',
      //TODO: refactor
      'photos.id',
      'photos.name',
      'photos.download.url',
      'avatar.id',
      'avatar.name',
      'avatar.download.url',
    ],
  },
  surveys: [
    personalInfo,
    partnerInfo,
    personalHistory,
    employmentQuestions,
    healthInfo,
    pregnancyHistory,
    deliveries,
    aboutYou,
    surrogacyJourney,
    signatureDisclosure,
  ],
};
