import React, { useMemo } from 'react';
import dayJs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/client';
import { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import { Box, Typography, IconButton, Grid, InputAdornment, SvgIcon } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import SearchIcon from '@mui/icons-material/Search';

import { appConfig } from '@app/app';
import { Paginated } from '@app/query';
import { error } from '@app/snackbars';
import { Table, useTableState } from '@app/ui/table';
import { Tooltip } from '@app/ui/tooltip';
import { Label } from '@app/ui/label';
import { useConfirmation } from '@app/ui/modal';
import { Text, DebouncedText, Date } from '@app/ui/forms';
import { Application } from '@app/application/types/Application';
import { ApplicationStatusEnum } from '@app/application/constants';
import { ApplicationFilter } from '@app/application/types';
import { applicationGetManyWithPagination } from '@app/application/gql';
import { ExportPdfDropdown } from '@app/application/components/pdf/ExportPdfDropdown';
import { getFieldsFromSurveys } from '@app/application/utils/survey';
import { applicationConfig } from '@app/application/configs';
import { ReactComponent as fileDownloadIcon } from '@app/app/images/icons/icon-file-download.svg';

import { USER_DELETE_ONE } from '../gql';
import { USER_STATUS_COLOR } from '../constants';
import { SurveyProgress } from './SurveyProgress';

export const AdminUserList = () => {
  const { t } = useTranslation('common');
  const { push } = useHistory();
  const [tableState, onChangeTableState] = useTableState<ApplicationFilter>({
    status: 'all',
    orderBy: [{ direction: 'desc', field: 'createdAt' }],
  });

  const preparedTableState = useMemo(() => {
    const { status, ...result } = tableState;

    if (result.createdAtGte) {
      result.createdAtGte = dayJs(result.createdAtGte, appConfig.format.isoDateTime)
        .startOf('day')
        .subtract(1, 'second')
        .utc()
        .add(1, 'second') // This is necessary due to a bug related to utc().
        .format(appConfig.format.isoDateTime);
    } else {
      result.createdAtGte = undefined;
    }

    if (result.createdAtLte) {
      result.createdAtLte = dayJs(result.createdAtLte, appConfig.format.isoDateTime)
        .endOf('day')
        .utc()
        .format(appConfig.format.isoDateTime);
    } else {
      result.createdAtLte = undefined;
    }

    if (status !== 'all') {
      return tableState;
    }

    return result;
  }, [tableState]);

  const fields = useMemo(() => {
    const { surveyFields } = getFieldsFromSurveys(applicationConfig.surveys);

    return [...surveyFields, 'status', 'createdAt', 'user.email', 'stripErrorFields'];
  }, []);

  const {
    data: { applicationGetManyWithPagination: data } = {},
    loading,
    refetch,
  } = useQuery<{
    applicationGetManyWithPagination: Paginated<Application>;
  }>(applicationGetManyWithPagination(fields), {
    variables: preparedTableState,
  });

  const [userDeleteOne, { loading: deleteLoading }] = useMutation<{ userDeleteOne: { id: number } }>(USER_DELETE_ONE);

  const [confirm] = useConfirmation({
    title: t('user.page.adminList.modal.title'),
    submitButtonText: t('general.button.delete'),
    isLoading: deleteLoading,
  });

  const confirmedDeleteOneUser = (id: number, name: string) => () =>
    confirm({
      onSubmit: async close => {
        try {
          await userDeleteOne({ variables: { id } });
          close();
          await refetch();
        } catch (e) {
          error(t('general.error.somethingWentWrong'));
        }
      },
      description: t('user.page.adminList.modal.description', {
        name,
      }),
    });

  const statusOptions = ['all', ...Object.values(ApplicationStatusEnum)].map(status => ({
    id: status,
    name: t(`application.field.status.option.${status}`),
  }));

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'user.name',
        headerName: `${t('user.field.userName.label')}`,
        flex: 1,
        minWidth: 200,
        renderCell: (params: GridRenderCellParams) => {
          return params.row?.stripErrorFields?.length > 0 ? (
            <Tooltip title={params.row.stripErrorFields.join(', ')} placement="top">
              <Typography variant="h5" color="error">
                {params.value}
              </Typography>
            </Tooltip>
          ) : (
            <>{params.value}</>
          );
        },
      },
      {
        field: 'user.email',
        headerName: `${t('user.field.email.label')}`,
        flex: 1,
        minWidth: 200,
      },
      {
        field: 'status',
        headerName: `${t('user.field.status.label')}`,
        renderCell: (params: GridRenderCellParams) => (
          <Label
            text={t(`application.field.status.option.${params.value}`)}
            color={USER_STATUS_COLOR[params.value]}
            sx={{ textTransform: 'capitalize' }}
          />
        ),
        flex: 1,
        minWidth: 150,
        maxWidth: 250,
      },
      {
        field: 'currentProgress',
        headerName: `${t('user.field.currentProgress.label')}`,
        renderCell: SurveyProgress,
        flex: 1,
        minWidth: 185,
        maxWidth: 190,
        sortable: false,
      },
      {
        field: 'exportPdf',
        type: 'actions',
        width: 100,
        minWidth: 130,
        align: 'left',
        sortable: false,
        getActions: (params: GridRowParams) => [
          <ExportPdfDropdown
            applicationId={params.row.id}
            label={<SvgIcon sx={{ fontSize: 14 }} component={fileDownloadIcon} viewBox="0 0 12 12" />}
            className="filledTonal"
            color="secondary"
            popperPlacement="top-start"
            sx={{
              p: 0.5,
              width: 33,
              height: 33,
              borderRadius: '50%',
            }}
          />,
        ],
      },
      {
        field: 'createdAt',
        headerName: `${t('user.field.createdAt.label')}`,
        flex: 1,
        minWidth: 150,
        maxWidth: 200,
        valueFormatter: ({ value }) => (value ? dayJs(value).format(appConfig.format.date) : ''),
      },
      {
        field: 'actions',
        headerName: `${t('user.field.actions.label')}`,
        type: 'actions',
        width: 150,
        minWidth: 150,
        align: 'right',
        getActions: (params: GridRowParams) => [
          <>
            <Tooltip title={t('user.page.adminList.detailsTooltip')} placement="top-end">
              <IconButton className="filledTonal" color="primary" onClick={() => push(`/admin/users/${params.row.id}`)}>
                <VisibilityOutlinedIcon sx={{ fontSize: 17 }} />
              </IconButton>
            </Tooltip>
            <IconButton color="error" onClick={confirmedDeleteOneUser(params.row.user.id, params.row.user.name)}>
              <DeleteIcon sx={{ fontSize: 17 }} />
            </IconButton>
          </>,
        ],
      },
    ],
    [],
  );

  return (
    <>
      <Typography variant="h2" gutterBottom>
        {t('user.page.adminList.title')}
      </Typography>
      <Box sx={{ mt: 3, mb: 3 }}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} sm={6} md={4}>
            <DebouncedText
              label={t('application.field.search.label')}
              placeholder={`${t('application.field.search.placeholder')}`}
              value={tableState.search}
              onChange={search => onChangeTableState({ search })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon fontSize="medium" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Text
              select
              label={t('application.field.status.label')}
              options={statusOptions}
              value={tableState.status}
              onChange={e => onChangeTableState({ status: e.target.value as ApplicationStatusEnum })}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Date
              disableFuture
              label={t('application.field.dateFrom.label')}
              value={tableState.createdAtGte}
              onChange={value => onChangeTableState({ createdAtGte: value })}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Date
              disableFuture
              label={t('application.field.dateTo.label')}
              value={tableState.createdAtLte}
              onChange={value => onChangeTableState({ createdAtLte: value })}
            />
          </Grid>
        </Grid>
      </Box>

      <Table
        loading={loading}
        columns={columns}
        rows={data?.rows || []}
        rowCount={data?.pagination?.totalItems || 0}
        state={tableState}
        onChangeState={onChangeTableState}
      />
    </>
  );
};
