import { ApplicationStatusEnum } from '@app/application/constants';

export const USER_STATUS_COLOR = {
  [ApplicationStatusEnum.lead as string]: 'info',
  [ApplicationStatusEnum.delivered as string]: 'success',
  [ApplicationStatusEnum.disqualified as string]: 'error',
};

export enum UserRoleEnum {
  admin = 'admin',
  surrogate = 'surrogate',
}

export const generalInfoItems = ['firstName', 'lastName', 'phone', 'user.email'] as const;
