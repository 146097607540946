import { memo } from 'react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { ToggleButton } from '@app/formik';

export interface DynamicFormFieldToggleButtonGroupProps {
  readonly name: string;
  readonly options: string[];
  readonly disabled?: boolean;
}

export const DynamicFormFieldToggleButtonGroup = memo(({ name, options }: DynamicFormFieldToggleButtonGroupProps) => {
  const { t } = useTranslation('common');
  const [{ value }] = useField(name);

  const data = Object.values(value || {});

  return (
    <>
      {options.map((item: string, index: number) => {
        const disabled = index !== 0 && data[index - 1] === undefined;

        return (
          <Box
            key={item}
            sx={{
              '&:not(:last-of-type)': {
                mb: 3,
                pb: 3,
                borderBottomWidth: '1px',
                borderBottomStyle: 'solid',
                borderColor: theme => theme.palette.grey[disabled ? 100 : 500],
              },
            }}
          >
            <ToggleButton
              name={`${name}.${item}`}
              label={t(`application.field.${name}.${item}.label`)}
              disabled={disabled}
            />
          </Box>
        );
      })}
    </>
  );
});
