import { ReactNode, memo } from 'react';
import {
  FormControl,
  FormLabel,
  ToggleButtonGroup,
  ToggleButton as MuiToggleButton,
  ToggleButtonGroupProps,
  FormHelperText,
} from '@mui/material';

import { Option } from '../types';
import { TextLabel } from './TextLabel';

export interface ToggleButtonProps extends ToggleButtonGroupProps {
  readonly options: Option[];
  readonly helperText?: ReactNode;
  readonly label?: ReactNode;
  readonly description?: ReactNode;
  readonly required?: boolean;
  readonly error?: boolean;
}

export const ToggleButton = memo(
  ({
    options,
    helperText,
    label,
    description,
    required,
    error,
    disabled,
    value,
    onChange,
    ...props
  }: ToggleButtonProps) => {
    return (
      <FormControl required={required} error={error} disabled={disabled} fullWidth>
        {label && (
          <FormLabel sx={{ mb: 2 }} required={false}>
            <TextLabel label={label} required={required} description={description} />
          </FormLabel>
        )}

        <ToggleButtonGroup
          color="primary"
          value={value}
          exclusive
          onChange={onChange}
          disabled={disabled}
          fullWidth
          {...props}
        >
          {options.map(option => (
            <MuiToggleButton
              key={option.id}
              value={option.id}
              sx={{
                padding: '9px 20px',
                borderRadius: '10px !important',
                width: {
                  sx: '100%',
                  sm: 140,
                },
                '&.MuiButtonBase-root:not(:first-of-type)': {
                  ml: 2,
                  borderColor: theme => theme.palette.grey[500],
                },
                '&:hover': {
                  backgroundColor: theme => theme.palette.primaryLight.light,
                },
                '&.Mui-selected': {
                  color: theme => theme.palette.text.primary,
                  backgroundColor: theme => theme.palette.primary.light,
                  borderColor: theme => `${theme.palette.primary.main} !important`,
                },
              }}
            >
              {option.name}
            </MuiToggleButton>
          ))}
        </ToggleButtonGroup>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  },
);
