import { useEffect } from 'react';
import PubSub from 'pubsub-js';

import { useAuthActions } from './useAuthActions';

export const useAuthSignOutSubscriber = () => {
  const { signOut } = useAuthActions();

  useEffect(() => {
    PubSub.subscribe('signOut', () => {
      signOut().catch(e => console.error(e));
    });

    return () => {
      PubSub.unsubscribe('signOut');
    };
  }, [signOut]);
};
