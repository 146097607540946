import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Modal } from '@app/ui/modal';
import { EmailChangeForm } from '@app/auth/types/forms';
import { Text, yup } from '@app/formik';
import { error, success } from '@app/snackbars';
import { ApolloError, useMutation } from '@apollo/client';
import { User } from '@app/user/types/User';
import { AUTH_CHANGE_EMAIL } from '@app/auth/gql';
import { extractValidationErrors } from '@app/query';

const initialValues = {
  email: '',
};

export interface ChangeEmailModalProps {
  readonly open: boolean;
  readonly onClose: () => void;
}

export const ChangeEmailModal = ({ open, onClose }: ChangeEmailModalProps) => {
  const { t } = useTranslation('common');
  const [changeEmail, { loading }] = useMutation<{ authChangeEmail: User }>(AUTH_CHANGE_EMAIL);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().email().required(),
      }),
    [t],
  );

  const handleSubmit = useCallback(
    async (input: EmailChangeForm, helpers: FormikHelpers<EmailChangeForm>) => {
      try {
        await changeEmail({ variables: { input } });
        onClose();
        success(t('auth.page.changeEmail.successMessage'));
      } catch (e: any) {
        const errors = extractValidationErrors(e as ApolloError);

        if (Object.keys(errors).length > 0) {
          helpers.setErrors(errors);
        } else {
          error(t('general.error.somethingWentWrong'));
        }
      }
    },
    [t],
  );

  return (
    <Modal open={open} onClose={onClose} maxWidth="sm" title={t('profile.modal.email.title')}>
      <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        {() => (
          <Form noValidate>
            <Text label={t('user.field.enterNewEmail.label')} required name="email" margin="normal" />

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', pt: 1 }}>
              <Button color="primary" onClick={onClose}>
                {t('general.button.cancel')}
              </Button>

              <LoadingButton loading={loading} color="primary" variant="contained" type="submit">
                {t('general.button.confirm')}
              </LoadingButton>
            </Box>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
