import { flatten, unflatten } from 'flat';

export const omitDeep = <T = Record<any, any>>(
  object: T,
  fields: string[],
  convertNullToUndefinedExcludeFields?: string[],
) => {
  return unflatten<Record<any, any>, T>(
    Object.entries(flatten(object) as Record<any, any>).reduce((acc, [field, value]) => {
      if (!fields.includes(field)) {
        if (
          convertNullToUndefinedExcludeFields &&
          !convertNullToUndefinedExcludeFields.includes(field) &&
          value === null
        ) {
          acc[field] = undefined;
        } else {
          acc[field] = value;
        }
      }

      return acc;
    }, {} as Record<any, any>),
  );
};
