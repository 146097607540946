import MuiAlert, { AlertProps as MuiAlertProps } from '@mui/material/Alert';
import { ReportProblemOutlined, Done, NotInterested, InfoOutlined } from '@mui/icons-material';

export interface AlertProps extends MuiAlertProps {
  readonly hideIcon?: boolean;
  readonly inner?: boolean;
}
export const Alert = ({ children, severity, onClose, sx, inner = false, hideIcon = false }: AlertProps) => {
  return (
    <MuiAlert
      severity={severity}
      onClick={onClose}
      onClose={onClose}
      variant="outlined"
      iconMapping={{
        error: <ReportProblemOutlined fontSize="inherit" />,
        success: <Done fontSize="inherit" />,
        warning: <NotInterested fontSize="inherit" />,
        info: <InfoOutlined fontSize="inherit" />,
      }}
      icon={hideIcon ? false : null}
      sx={[
        inner && {
          boxShadow: 'none',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <div>{children}</div>
    </MuiAlert>
  );
};
