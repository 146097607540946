import { useEffect } from 'react';

import { applicationConfig } from '../configs';
import uploadImage from '../images/card-application-upload.jpg';

export const useCardImagePreload = () => {
  useEffect(() => {
    const images = applicationConfig.surveys.reduce((acc, survey) => {
      if (survey?.meta?.cardImage) {
        acc.push(survey?.meta?.cardImage);
      }

      return acc;
    }, [] as string[]);

    images.push(uploadImage);

    images.forEach(image => {
      const img = new Image();
      img.src = image;
    });
  }, []);
};
