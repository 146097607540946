import axios from 'axios';
import imageCompression from 'browser-image-compression';
import { getI18n } from 'react-i18next';

import { File as FileObject } from '@app/file/types';
import { getMimeType } from '@app/file/utils';
import { warning } from '@app/snackbars';

export const prependImageExifMeta = (images: FileObject[]) => {
  return Promise.all(
    images.map(async image => {
      if (image?.download?.url) {
        try {
          const response = await axios.get(image.download?.url, { responseType: 'arraybuffer' });

          const file = new File([new Blob([response.data])], image.name, {
            type: getMimeType(image.name),
          });

          image.download.file = await imageCompression.getDataUrlFromFile(
            await imageCompression(file, { useWebWorker: false }),
          );
        } catch (e) {
          const i18n = getI18n();
          warning(i18n.t('common:application.pdf.error.notSupported', { filename: image.name }));
        }
      }
    }),
  );
};
