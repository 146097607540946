import React from 'react';

import { Alert, AlertProps } from '@app/ui/alert';

// @ts-ignore
const Message = React.forwardRef((props: AlertProps, ref: React.RefObject<HTMLElement>) => (
  <span ref={ref}>
    <Alert {...props} />
  </span>
));

Message.displayName = 'Message';

export default Message;
