import { useCallback, useState, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Container, Link, Box, AppBar, Toolbar, Button, IconButton, Typography, useTheme } from '@mui/material';
import { Menu as MenuIcon, ExitToApp as ExitToAppIcon } from '@mui/icons-material';

import { Drawer } from '@app/ui/drawer';
import { Menu } from '@app/ui/menu';
import { useIsMobile } from '@app/app/hooks/useIsMobile';
import { useAuthActions, useAuth } from '@app/auth';

import { LogoLink } from './LogoLink';

export interface Props {
  children: ReactNode;
}

export const MainLayout = ({ children }: Props) => {
  const { t } = useTranslation('common');
  const location = useLocation();
  const { zIndex } = useTheme();
  const isMobile = useIsMobile();
  const { user, isAdmin } = useAuth();
  const { signOut } = useAuthActions();

  const [mainMenuOpen, setMainMenuOpen] = useState(false);

  const handleMainMenuToggle = useCallback(() => {
    if (isMobile) {
      setMainMenuOpen(!mainMenuOpen);
    }
  }, [mainMenuOpen]);

  const startsWith = (urls: string[]) => urls.some((url: string) => location?.pathname?.startsWith(url));

  const menu = [
    {
      name: t('application.page.title'),
      to: '/surrogate-application',
      active: startsWith(['/surrogate-application']),
      onClick: handleMainMenuToggle,
      show: !isAdmin,
    },
    {
      name: t('profile.page.title'),
      to: '/my-account',
      active: startsWith(['/my-profile']),
      onClick: handleMainMenuToggle,
      show: isMobile,
    },
    {
      name: t('user.page.adminList.title'),
      to: '/admin/users',
      active: startsWith(['/admin/users']),
      onClick: handleMainMenuToggle,
      show: isAdmin,
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
      }}
    >
      {isMobile && (
        <Drawer
          open={mainMenuOpen}
          handleClose={handleMainMenuToggle}
          header={
            <>
              <IconButton
                sx={{
                  color: 'text.primary',
                  position: 'absolute',
                  top: '17px',
                  left: '6px',
                  padding: '6px',

                  '& svg': {
                    fontSize: 20,
                  },
                }}
                onClick={handleMainMenuToggle}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h4" component="h3">
                {t('general.menu.title')}
              </Typography>
            </>
          }
        >
          <Menu menu={menu} variant="vertical" />

          <Button
            onClick={signOut}
            sx={{
              fontWeight: 400,
              width: '100%',
              justifyContent: 'space-between',
              py: 1,
              px: 0.5,
              '& .MuiButton-endIcon': {
                m: 0,
              },
              '& svg': {
                fontSize: 18,
                ml: 1,
              },
            }}
            endIcon={<ExitToAppIcon fontSize="inherit" />}
          >
            {t('general.button.logout')}
          </Button>
        </Drawer>
      )}

      <AppBar
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: zIndex.appBar,
          bgcolor: isAdmin ? 'grey.100' : '#FFF',
        }}
        color="default"
        position="static"
      >
        <Container maxWidth="lg">
          <Toolbar
            sx={{
              justifyContent: 'space-between',
            }}
            disableGutters
          >
            {isMobile && (
              <IconButton
                sx={{
                  color: 'text.primary',
                  fontSize: 24,
                  padding: '6px',
                  marginLeft: '-6px',
                  marginRight: '5px',
                }}
                onClick={handleMainMenuToggle}
              >
                <MenuIcon fontSize="inherit" />
              </IconButton>
            )}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <LogoLink />
              {!isMobile && (
                <Menu
                  sx={{
                    '& .nav-menu-item': {
                      height: 60,
                    },
                    marginLeft: {
                      sm: '30px',
                      md: '40px',
                      lg: '50px',
                    },
                  }}
                  menu={menu}
                />
              )}
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                minWidth: 36,
              }}
            >
              {!isMobile && (
                <>
                  <Link underline="none" color="text.secondary" component={RouterLink} to="/my-account">
                    {user?.name}
                  </Link>
                  <IconButton
                    onClick={signOut}
                    sx={{
                      color: 'text.secondary',
                      fontSize: 18,
                      padding: '6px',
                      marginRight: '-6px',
                      ml: 3,
                    }}
                  >
                    <ExitToAppIcon fontSize="inherit" />
                  </IconButton>
                </>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          pt: 5,
          paddingLeft: {
            md: '40px',
            lg: '60px',
          },
          paddingRight: {
            md: '40px',
            lg: '60px',
          },
        }}
      >
        <Box
          component="main"
          sx={{
            minWidth: 0,
            flexGrow: 1,
            pb: 5,
          }}
        >
          {!!user ? children : <></>}
        </Box>
      </Container>
    </Box>
  );
};
