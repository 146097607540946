import { Route, Switch, Redirect } from 'react-router-dom';

import { SignInPage } from './sign-in';
import { SignUpConfirmPage } from './sign-up-confirm';
import { PasswordResetPage } from './password-reset';
import { DisqualifiedPage } from './disqualified';

export const Public = () => {
  return (
    <Switch>
      <Route exact path="/sign-in" component={SignInPage} />
      <Route exact path="/sign-up/:token" component={SignUpConfirmPage} />
      <Route exact path="/password-reset" component={PasswordResetPage} />
      <Route exact path="/disqualified" component={DisqualifiedPage} />
      <Redirect to="/sign-in" />
    </Switch>
  );
};
