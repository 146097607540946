import { AuthLayout } from '@app/layouts';
import { SignIn } from '@app/auth/components/SignIn';

export const SignInPage = () => {
  return (
    <AuthLayout>
      <SignIn />
    </AuthLayout>
  );
};
