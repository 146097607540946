import { get } from '@app/app/utils/get';

import { DynamicFormConfigCommon, DynamicFormConfigPage, DynamicFormConfigSurvey } from '../types';

export const getFieldNameByIndex = (name: string, index: number) => {
  return name.replace('{index}', String(index));
};

export const pageCopyWithNewIndex = ({ array, elements, ...config }: DynamicFormConfigPage, index: number) => {
  return {
    ...config,
    variables: {
      ...(config?.variables || {}),
      pageArrayNumber: index + 1,
    },
    elements: elements.map(element => ({ ...element, name: getFieldNameByIndex(element.name, index) })),
  };
};

export const pageTransformArrays = (pages: DynamicFormConfigPage[], values: Record<any, any>) => {
  return pages
    .map(page => {
      if (!page.array) {
        return page;
      }

      const length = page.array.length(values);

      const pageArray: DynamicFormConfigPage[] = [];

      for (let i = 0; i < length; i += 1) {
        pageArray.push(pageCopyWithNewIndex(page, i));
      }

      return pageArray;
    })
    .flat();
};

export const surveyTransformArrays = <T = string>(survey: DynamicFormConfigSurvey<T>, values: Record<any, any>) => {
  if (!survey.array) {
    return survey;
  }

  const length = survey.array.length(values);

  const pages: DynamicFormConfigPage[] = [];

  for (let i = 0; i < length; i += 1) {
    pages.push(
      ...survey.pages
        .filter(page => i === 0 || (!page.information && (!page.single || page.single.index === i)))
        .map(page => pageCopyWithNewIndex(page, i)),
    );
  }

  if (!length) {
    pages.push(...survey.pages.filter(page => !!page.single));
  }

  return { ...survey, pages };
};

export const inArray = (
  visibleIf: (inArrayData: Record<any, any>, data: Record<any, any>, config: DynamicFormConfigCommon) => boolean,
) => {
  return (data: Record<any, any>, config: DynamicFormConfigCommon) => {
    // @ts-ignore
    const name: string = config.elements?.length > 0 ? config.elements[0].name : config.name;

    const arrayName = name.split('.');

    const numberOfIndex = arrayName.findIndex(value => !Number.isNaN(Number(value)));

    return visibleIf(
      {
        index: Number(arrayName[numberOfIndex]),
        ...(get(data, arrayName.slice(0, numberOfIndex + 1).join('.')) || {}),
      },
      data,
      config,
    );
  };
};
