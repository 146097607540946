import { ReactNode } from 'react';
import { Box } from '@mui/material';

import { Tooltip } from '@app/ui/tooltip';

export interface TextLabelProps {
  readonly label: ReactNode;
  readonly description?: ReactNode;
  readonly required?: boolean;
}

export const TextLabel = ({ label, description, required }: TextLabelProps) => {
  return (
    <Box>
      {label}
      {required && <>&nbsp;*</>}
      {description && (
        <Box sx={{ whiteSpace: 'nowrap' }} component="span">
          &nbsp;
          <Tooltip title={description} />
        </Box>
      )}
    </Box>
  );
};
