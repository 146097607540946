import { Text, TextProps } from './Text';
import { useEnumOptions, UseEnumOptions } from '../../hooks/useEnumOptions';

export type EnumSelectProps = Omit<TextProps, 'options' | 'select'> & UseEnumOptions;

export const EnumSelect = ({ enum: data, translation, ...props }: EnumSelectProps) => {
  const options = useEnumOptions({ translation, enum: data });

  return <Text {...props} select options={options} />;
};
