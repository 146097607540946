import { useMemo } from 'react';
import { useFormikContext } from 'formik';

import { Text, TextProps } from '@app/ui/forms';

export interface ComputedTextProps extends TextProps {
  readonly formula: (data: Record<any, any>) => any;
}

export const ComputedText = ({ formula, name, ...props }: ComputedTextProps) => {
  const { values } = useFormikContext<Record<any, any>>();

  const value = useMemo(() => formula(values), [values]);

  return <Text value={value} {...props} />;
};
