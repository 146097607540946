import { get } from '@app/app/utils/get';

import { isVisible } from './visibleIf';
import { DynamicFormElementType } from '../constants';
import { DynamicFormConfigPage, DynamicFormConfigSurvey } from '../types';
import { pageTransformArrays, surveyTransformArrays } from './array';

export const isAnswered = (value: any) => {
  return value !== null && value !== '' && value !== undefined && (!Array.isArray(value) || value.length > 0);
};

export const isPageCompleted = (page: DynamicFormConfigPage, values: Record<any, any>) => {
  if (page.information) {
    return true;
  }

  return page.elements.every(element => {
    if (!isVisible(element, values) || element.optional || element.type === DynamicFormElementType.information) {
      return true;
    }

    const value = get(values, element.name);

    if (element.type === DynamicFormElementType.toggleButtonGroup) {
      return value && element.props?.options?.every(name => value[name] !== undefined);
    }

    const result = isAnswered(value);

    if (result && element?.validIf && !element.validIf(value)) {
      return false;
    }

    return result;
  });
};

export const calculateSurveyProgress = (surveys: DynamicFormConfigSurvey[], item: Record<any, any>) => {
  const result = surveys.reduce(
    (acc, rawSurvey) => {
      const survey = surveyTransformArrays(rawSurvey, item);

      if (isVisible(survey, item)) {
        if (!(survey.name in acc.populatedSurveyCountMap)) {
          acc.populatedSurveyCountMap[survey.name] = {
            pageCount: 0,
            populatedPageCount: 0,
          };
        }

        pageTransformArrays(survey.pages, item).forEach(page => {
          if (!page?.information && isVisible(page, item)) {
            acc.populatedSurveyCountMap[survey.name].pageCount += 1;

            if (isPageCompleted(page, item)) {
              acc.populatedSurveyCountMap[survey.name].populatedPageCount += 1;
            }
          }
        });

        acc.populatedPageCountTotal += acc.populatedSurveyCountMap[survey.name].populatedPageCount;
        acc.pageCountTotal += acc.populatedSurveyCountMap[survey.name].pageCount;
      }

      return acc;
    },
    { pageCountTotal: 0, progressTotal: 0, populatedPageCountTotal: 0, populatedSurveyCountMap: {} } as {
      pageCountTotal: number;
      progressTotal: number;
      populatedPageCountTotal: number;
      populatedSurveyCountMap: Record<any, { pageCount: number; populatedPageCount: number }>;
    },
  );

  result.progressTotal = Math.round((result.populatedPageCountTotal / result.pageCountTotal) * 100);

  return result;
};
