import { AuthLayout } from '@app/layouts';
import { PasswordReset } from '@app/auth/components/PasswordReset';

export const PasswordResetPage = () => {
  return (
    <AuthLayout>
      <PasswordReset />
    </AuthLayout>
  );
};
