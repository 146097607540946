import { PDFViewer } from '@react-pdf/renderer';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { ApplicationPdfDocument } from '@app/application/components/pdf/ApplicationPdfDocument';
import { ApplicationPdf } from '@app/application/constants';
import { getPdfFields } from '@app/application/utils/survey';
import { applicationConfig } from '@app/application/configs';
import { Application } from '@app/application/types/Application';
import { applicationGetOne } from '@app/application/gql';
import { Loader } from '@app/ui/loader';

export const AdminSingleUserPdfPage = () => {
  const { id, type } = useParams<{ id: string; type: string }>();

  const fields = getPdfFields(applicationConfig.surveys, type as ApplicationPdf);

  const { data } = useQuery<{ applicationGetOne: Application }>(applicationGetOne(fields), {
    variables: { id: Number(id) },
  });

  return data?.applicationGetOne ? (
    <PDFViewer width="100%" height="100%">
      <ApplicationPdfDocument data={data?.applicationGetOne} type={type as ApplicationPdf} />
    </PDFViewer>
  ) : (
    <Loader isLoading />
  );
};
