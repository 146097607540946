import { FC, useMemo } from 'react';

import { array } from './array';
import { visibleIf } from './visibleIf';
import { DynamicFormComponentProps, DynamicFormConfigPage } from '../types';

export const extend =
  <Props extends DynamicFormComponentProps<DynamicFormConfigPage>>(Component: FC<Props>) =>
  (props: Props) => {
    const DynamicExtendedComponent = useMemo(() => {
      let ExtendedComponent = props.config.visibleIf ? visibleIf<Props>(Component) : Component;

      ExtendedComponent = props.config.array ? array<Props>(ExtendedComponent) : ExtendedComponent;

      return ExtendedComponent;
    }, [props.config.visibleIf, props.config.array]);

    return <DynamicExtendedComponent {...props} />;
  };
