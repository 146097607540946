import { useState, ReactNode, useCallback, useEffect, useMemo } from 'react';
import deepEqual from 'fast-deep-equal';
import { useLazyQuery } from '@apollo/client';

import { Loader } from '@app/ui/loader';
import { User } from '@app/user/types/User';

import { State } from '../types/State';
import { GET_AUTH_USER } from '../gql';
import { AuthContext, defaultState } from '../context';
import { getIdToken } from '../utils/cognito';

interface Query {
  authWhoAmI: User;
}

export interface AuthProviderProps {
  readonly children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [state, setState] = useState<Partial<State>>(defaultState);

  const [getAuthUser] = useLazyQuery<Query>(GET_AUTH_USER, { fetchPolicy: 'network-only' });

  const updateState = useCallback(
    (newState: Partial<State>) => {
      if (!deepEqual(newState, state)) {
        setState({ ...state, ...newState });
      }
    },
    [state],
  );

  useEffect(() => {
    (async () => {
      const token = await getIdToken();

      if (token) {
        updateState({ token, isReady: true });
      } else {
        updateState({ isReady: true });
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (state.token) {
        try {
          const { data } = await getAuthUser();

          if (data?.authWhoAmI) {
            updateState({ user: data.authWhoAmI });
          }
        } catch (e) {
          //
        }
      }
    })();
  }, [state.token]);

  const value = useMemo(() => ({ state, updateState }), [state, updateState]);

  return state.isReady ? <AuthContext.Provider value={value}>{children}</AuthContext.Provider> : <Loader isLoading />;
};
