import { useState, useCallback } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { Alert } from '@app/ui/alert';
import { Loader } from '@app/ui/loader';
import { error } from '@app/snackbars';
import { Text, yup } from '@app/formik';
import { useAuthActions } from '@app/auth';

import { PasswordResetForm } from '../types/forms';

const initialValues = {
  email: '',
};

const validationSchema = yup.object().shape({
  email: yup.string().required().email(),
});

export const PasswordReset = () => {
  const { t } = useTranslation('common');
  const [isSuccess, setIsSuccess] = useState(false);
  const { isLoading, forgotPassword } = useAuthActions();

  const handleSubmit = useCallback(async (data: PasswordResetForm) => {
    try {
      await forgotPassword(data);
      setIsSuccess(true);
    } catch (e: any) {
      error(e.message);
    }
  }, []);

  return (
    <>
      <Loader isLoading={isLoading} />

      <Typography variant="h1" gutterBottom>
        {t('auth.page.passwordReset.title')}
      </Typography>
      {isSuccess ? (
        <Alert severity="success" hideIcon inner>
          {t('auth.page.passwordReset.successMessage')}
        </Alert>
      ) : (
        <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
          {() => (
            <Form noValidate>
              <Text required label={t('user.field.email.label')} name="email" type="email" margin="normal" />

              <Button
                sx={{
                  width: {
                    xs: '100%',
                    sm: 'auto',
                  },
                }}
                type="submit"
                variant="contained"
                color="primary"
              >
                {t('auth.button.resetPassword')}
              </Button>
            </Form>
          )}
        </Formik>
      )}

      <Box mt={3}>
        <Button component={RouterLink} to="/sign-in" color="primary">
          {t('auth.button.backToSignIn')}
        </Button>
      </Box>
    </>
  );
};
