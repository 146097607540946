import { useCallback, useState } from 'react';

import { TableState } from '../types/TableState';

export const useTableState = <Filter = Record<any, any>>(
  initialState: Partial<TableState> = {},
): [TableState<Filter>, (newState: Partial<TableState<Filter>>) => void] => {
  const [state, setState] = useState<TableState>({
    page: 1,
    orderBy: [],
    limit: 30,
    ...initialState,
  });

  const handleChangeState = useCallback((newState: Partial<TableState<Filter>>) => {
    setState(oldState => ({ ...oldState, ...newState }));
  }, []);

  return [state, handleChangeState];
};
