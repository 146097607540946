import { useState, SyntheticEvent, useCallback, memo } from 'react';
import { Autocomplete as MuiAutocomplete } from '@mui/material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

import { Text, TextProps } from './Text';
import { Option } from '../types';

export type AutocompleteProps = Omit<TextProps, 'options' | 'onChange' | 'value'> & {
  readonly options: Option[];
  readonly value: any;
  readonly onChange?: (event: SyntheticEvent, value: Option, reason: string) => void;
};

export const Autocomplete = memo(({ options, value, onChange, disabled, readOnly, ...props }: AutocompleteProps) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = useCallback((event: SyntheticEvent, newInputValue: any) => {
    setInputValue(newInputValue);
  }, []);

  return (
    <MuiAutocomplete
      disableClearable
      readOnly={readOnly}
      value={value || null}
      onChange={onChange}
      inputValue={inputValue}
      getOptionLabel={option => {
        if (typeof option === 'object') {
          return option.name;
        }
        return String(option ? options.find(newOption => String(newOption.id) === option)?.name : '');
      }}
      onInputChange={handleInputChange}
      options={options}
      classes={{
        listbox: 'styled-scrollbar',
      }}
      disabled={disabled}
      popupIcon={<KeyboardArrowDown />}
      renderInput={params => <Text {...params} {...props} />}
    />
  );
});
