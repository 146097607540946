import { useMemo } from 'react';

import { Option } from '@app/ui/forms/types';

export const useOptionVisibleIf = <T extends Option>(options: T[], optionVisibleIf?: (option: Option) => boolean) => {
  return useMemo(() => {
    if (!options || !optionVisibleIf) {
      return options;
    }

    return options.filter(optionVisibleIf);
  }, [options, optionVisibleIf]);
};
