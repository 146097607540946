import { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Link, Typography } from '@mui/material';

import { useAuth, useAuthActions } from '@app/auth';
import { Loader } from '@app/ui/loader';
import { Alert } from '@app/ui/alert';
import { error } from '@app/snackbars';
import { useConfirmation } from '@app/ui/modal';

import { ApplicationList } from './ApplicationList';
import { Application } from '../../types/Application';
import { APPLICATION_SUBMIT, applicationGetOne } from '../../gql';
import { applicationConfig } from '../../configs';
import { getFieldsFromSurveys } from '../../utils/survey';
import { UserFinishApplication } from '../UserFinishApplication';
import { ApplicationStatusEnum } from '../../constants';

export const UserApplicationItem = () => {
  const { t } = useTranslation('common');
  const { push } = useHistory();
  const { application: currentApplication } = useAuth();
  const { signOut, isLoading } = useAuthActions();
  const [isSuccess, setIsSuccess] = useState(false);

  const fields = useMemo(() => {
    const { surveyFields } = getFieldsFromSurveys(applicationConfig.surveys);

    return [...surveyFields, 'isPhotosSubmitted'];
  }, []);

  const { data, loading } = useQuery<{ applicationGetOne: Application }>(applicationGetOne(fields), {
    variables: { id: currentApplication?.id },
  });

  const [applicationSubmit, { loading: isSubmitLoading }] = useMutation<{ applicationSubmit: Partial<Application> }>(
    APPLICATION_SUBMIT,
  );

  const handleSurveyClick = useCallback((survey: string) => push(`/surrogate-application/${survey}`), []);

  const handleSubmit = useCallback(
    async (hideModal: () => void) => {
      try {
        const response = await applicationSubmit({ variables: { input: { id: currentApplication?.id } } });

        const application = response?.data?.applicationSubmit;

        if (application?.status === ApplicationStatusEnum.disqualified) {
          await signOut();
          push('/disqualified');
        } else if (!!application?.status) {
          setIsSuccess(true);
        }

        hideModal();
      } catch (e) {
        error(t('general.error.somethingWentWrong'));
      }
    },
    [t, currentApplication?.id],
  );

  const [confirm] = useConfirmation({
    title: t('application.button.finishAndSubmit.name'),
    submitButtonText: t('application.button.finishAndSubmit.name'),
    onSubmit: handleSubmit,
    isLoading: isSubmitLoading || isLoading,
  });

  return (
    <>
      {isSuccess ? (
        <UserFinishApplication onBack={() => setIsSuccess(false)} />
      ) : (
        <>
          {data?.applicationGetOne?.status &&
            ![ApplicationStatusEnum.lead, ApplicationStatusEnum.applicants].includes(
              data?.applicationGetOne?.status as ApplicationStatusEnum,
            ) &&
            !data?.applicationGetOne?.isPhotosSubmitted && (
              <Alert severity="info" sx={{ mb: 4 }} inner>
                <Trans
                  i18nKey={t('application.finish.upload.notification')}
                  components={{
                    a: <Link component={RouterLink} to="/surrogate-application/upload-photos" />,
                  }}
                />
              </Alert>
            )}
          <Typography variant="h1" gutterBottom>
            {t('application.page.title')}
          </Typography>
          <Typography paragraph>
            {t('application.page.description', { name: currentApplication?.firstName })}
          </Typography>

          {data?.applicationGetOne && (
            <ApplicationList item={data.applicationGetOne} onClick={handleSurveyClick} onSubmit={confirm} />
          )}
        </>
      )}

      <Loader isLoading={loading || isSubmitLoading || isLoading} />
    </>
  );
};
