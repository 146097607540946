import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';

import { Card } from '@app/ui/card';
import { Breadcrumbs } from '@app/ui/breadcrumbs';

import cardImage from '../images/card-applicationn-final.jpg';

export interface UserFinishApplicationProps {
  readonly isDisqualify?: boolean;
  readonly onBack?: () => void;
}

export const UserFinishApplication = ({ isDisqualify = false, onBack }: UserFinishApplicationProps) => {
  const { t } = useTranslation('common');
  const { push } = useHistory();

  const pageTitle = t(`application.finish.${isDisqualify ? 'disqualify' : 'thankYou'}.title`);

  const breadcrumbs = useMemo(
    () => [
      {
        title: t('application.page.title'),
        to: '/surrogate-application',
      },
      {
        title: pageTitle,
      },
    ],
    [t],
  );

  const handleUpload = useCallback(() => push('/surrogate-application/upload-photos'), []);

  return (
    <Box sx={{ maxWidth: 670, mx: 'auto' }}>
      <Breadcrumbs options={breadcrumbs} />

      <Card size="large" img={cardImage}>
        <Typography variant="h1" gutterBottom>
          {pageTitle}
        </Typography>
        {isDisqualify ? (
          <>
            <Typography paragraph>{t('application.finish.disqualify.description.item1')}</Typography>
            <Typography>{t('application.finish.disqualify.description.item2')}</Typography>
          </>
        ) : (
          <>
            <Typography paragraph>{t('application.finish.thankYou.description.item1')}</Typography>
            <Typography>{t('application.finish.thankYou.description.item2')}</Typography>
            <Box sx={{ display: { sm: 'flex' }, justifyContent: 'flex-end', mt: 3 }}>
              <Button
                sx={{ width: { xs: '100%', sm: 'auto' } }}
                variant="contained"
                color="primary"
                onClick={handleUpload}
              >
                {t('application.button.uploadNow.name')}
              </Button>
              <Button
                className="filledTonal"
                sx={{ width: { xs: '100%', sm: 'auto' }, ml: { xs: 0, sm: 2 }, mt: { xs: 2, sm: 0 } }}
                variant="contained"
                color="primary"
                onClick={onBack}
              >
                {t('application.button.uploadLater.name')}
              </Button>
            </Box>
          </>
        )}
      </Card>
    </Box>
  );
};
