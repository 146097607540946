import { LightLayout } from '@app/layouts';
import { UserFinishApplication } from '@app/application/components/UserFinishApplication';

export const DisqualifiedPage = () => {
  return (
    <LightLayout>
      <UserFinishApplication isDisqualify />
    </LightLayout>
  );
};
