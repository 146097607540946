import { memo, forwardRef } from 'react';
import { IMaskInput } from 'react-imask';
import { AnyMaskedOptions, MaskedPattern } from 'imask';

import { Text as MuiText, TextProps as MuiTextProps } from './Text';

export interface FormattedTextProps extends MuiTextProps {
  readonly mask: string;
  readonly definitions?: { [key: string]: MaskedPattern.PatternDefinition };
  readonly blocks?: { [key: string]: AnyMaskedOptions };
  readonly maskPlaceholder?: string;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMask = forwardRef<HTMLElement, CustomProps>((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      // @ts-ignore
      inputRef={ref}
      onChange={() => {}}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export const FormattedText = memo(
  ({ value, mask, definitions, blocks, maskPlaceholder, length = 17, ...props }: FormattedTextProps) => {
    return (
      <MuiText
        {...props}
        value={value !== undefined && value !== null ? String(value) : value}
        // @ts-ignore
        InputProps={{ inputComponent: TextMask }}
        inputProps={{ mask, definitions, blocks, placeholder: maskPlaceholder, maxLength: length }}
      />
    );
  },
);

FormattedText.displayName = 'FormattedText';
