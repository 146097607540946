import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Typography } from '@mui/material';
import { useIsMobile } from '@app/app/hooks/useIsMobile';

import bgMain from '@app/app/images/bg-auth-main.jpg';
import bgMainSmall from '@app/app/images/bg-auth-main-small.jpg';

import { LogoLink } from './LogoLink';

export interface Props {
  children?: React.ReactNode;
}

export const AuthLayout = ({ children }: Props) => {
  const { t } = useTranslation('common');
  const isMobile = useIsMobile();

  return (
    <Container
      disableGutters
      sx={{
        backgroundColor: '#FFF',
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        height: {
          md: '100%',
        },
        minHeight: {
          xs: '100%',
          md: 0,
        },
      }}
      maxWidth={false}
    >
      <Box
        sx={{
          backgroundImage: `url(${isMobile ? bgMainSmall : bgMain})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50%',
          backgroundSize: 'cover',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          position: 'relative',
          flexBasis: {
            md: '60%',
            lg: '55%',
          },
          maxWidth: {
            md: '60%',
            lg: '55%',
          },
          height: {
            xs: '209px',
            sm: '300px',
            md: 'auto',
          },

          '&:before': {
            content: '""',
            backgroundColor: 'rgba(0,0,0,0.1)',
            position: 'absolute',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: 1,
          },
        }}
      >
        <Typography
          sx={{
            display: {
              xs: 'none',
              md: 'block',
            },
            position: 'absolute',
            maxWidth: 360,
            color: '#FFF',
            pt: 3,
            top: 40,
            left: 40,
            zIndex: 2,

            '&:before': {
              content: '""',
              backgroundColor: '#FFF',
              position: 'absolute',
              left: 0,
              top: 0,
              width: 60,
              height: 2,
            },
          }}
          variant="h3"
        >
          {t('auth.page.signIn.title')}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          flexBasis: {
            md: '40%',
            lg: '45%',
          },
          maxWidth: {
            md: '40%',
            lg: '45%',
          },
          padding: {
            xs: '40px 16px 24px',
            md: '22px 22px 22px 32px',
          },
          minHeight: {
            md: '100%',
          },
          overflow: {
            md: 'auto',
          },
          position: 'relative',
        }}
      >
        <Box
          sx={{
            textAlign: {
              xs: 'left',
              md: 'right',
            },
            paddingBottom: {
              xs: '32px',
              sm: '28px',
              md: '16px',
            },
          }}
        >
          <Box
            sx={{
              mx: 'auto',
              width: {
                xs: '100%',
                sm: 450,
                md: '100%',
              },
            }}
          >
            <LogoLink width={144} height={64} />
          </Box>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            display: {
              sm: 'flex',
            },
            justifyContent: {
              sm: 'center',
            },
            alignItems: {
              md: 'center',
            },
          }}
        >
          <Box
            sx={{
              width: {
                xs: '100%',
                sm: 450,
                md: 340,
              },
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
