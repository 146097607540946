import { cognitoClient } from '../configs/cognitoClient';

export async function getIdToken() {
  try {
    const session = await cognitoClient.currentSession();

    return session.getIdToken().getJwtToken();
  } catch (e) {
    return undefined;
  }
}
