import { useCallback, useMemo } from 'react';
import { camelCase } from 'change-case';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';

import { DynamicForm } from '@app/dynamic';
import { Card } from '@app/ui/card';
import { Loader } from '@app/ui/loader';
import { Breadcrumbs } from '@app/ui/breadcrumbs';

import { getName } from '../utils';
import defaultImageCard from '../images/card-application-personal-info.jpg';
import { useUpdateApplication } from '../hooks/useUpdateApplication';
import { applicationConfig } from '../configs';

export const AdminApplicationForm = () => {
  const { t } = useTranslation('common');
  const { push } = useHistory();
  const { id, survey: surveyParam } = useParams<{ id?: string; survey: string }>();
  const survey = camelCase(surveyParam);

  const surveyTitle = t(`application.field.survey.option.${survey}.name`);
  const handleBack = useCallback(() => push(`/admin/users/${id}`), [id]);

  const config = useMemo(() => applicationConfig.surveys.find(item => survey === item.name), [survey]);

  const { surveyConfig, initialValues, isUpdateApplicationLoading, isGetApplicationLoading, onSubmit } =
    useUpdateApplication({
      config,
      id: Number(id),
      isAdmin: true,
      onUpdateSuccess: handleBack,
    });

  const breadcrumbs = useMemo(
    () => [
      {
        title: t('user.page.adminList.title'),
        to: '/admin/users',
      },
      {
        title: initialValues ? getName(initialValues) : '',
        to: `/admin/users/${id}`,
      },
      {
        title: surveyTitle,
      },
    ],
    [t, surveyTitle, initialValues, id],
  );

  return (
    <Box sx={{ maxWidth: 670, mx: 'auto' }}>
      <Breadcrumbs options={breadcrumbs} />
      <Box mb={3} display="flex" alignItems="center">
        <Typography variant="h2">{surveyTitle}</Typography>
      </Box>

      <Card size="large" img={config?.meta?.cardImage || defaultImageCard}>
        {initialValues && surveyConfig && (
          <DynamicForm
            translation="application.field"
            onSubmit={onSubmit}
            onBack={handleBack}
            initialValues={initialValues}
            config={surveyConfig}
          />
        )}
      </Card>

      <Loader isLoading={isUpdateApplicationLoading || isGetApplicationLoading} />
    </Box>
  );
};
