import { Box, Link, BoxProps } from '@mui/material';

import logoImage from '@app/app/images/logo.svg';
import { appConfig } from '@app/app';

export interface LogoLinkProps extends BoxProps {}

export const LogoLink = (props: LogoLinkProps) => {
  return (
    <Link
      sx={{
        display: 'inline-block',
        lineHeight: 1,
        verticalAlign: 'top',
        '&:hover, &:focus': {
          opacity: 0.9,
          textDecoration: 'none',
        },
      }}
      href={appConfig.appUrl}
    >
      <Box width={100} height={45} component="img" src={logoImage} alt="logo" {...props} />
    </Link>
  );
};
