import { ReactNode, memo } from 'react';
import {
  FormControl,
  FormLabel,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormHelperText,
  FormGroup,
} from '@mui/material';

import { Option } from '../types';
import { TextLabel } from './TextLabel';

export interface CheckboxGroupProps extends Omit<CheckboxProps, 'value'> {
  readonly options: Option[];
  readonly value: Option['id'][];
  readonly helperText?: ReactNode;
  readonly label?: ReactNode;
  readonly description?: ReactNode;
  readonly required?: boolean;
  readonly disabled?: boolean;
  readonly error?: boolean;
}

export const CheckboxGroup = memo(
  ({
    options,
    helperText,
    label,
    required,
    error,
    disabled,
    description,
    value,
    onChange,
    name,
    ...props
  }: CheckboxGroupProps) => {
    return (
      <FormControl required={required} error={error} disabled={disabled}>
        {label && (
          <FormLabel sx={{ mb: 2 }} required={false}>
            <TextLabel label={label} required={required} description={description} />
          </FormLabel>
        )}

        <FormGroup>
          {options.map(option => (
            <FormControlLabel
              key={option.id}
              value={option.id}
              checked={value.includes(option.id)}
              control={<Checkbox name={name} onChange={onChange} {...props} />}
              label={option.name}
              labelPlacement="end"
            />
          ))}
        </FormGroup>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  },
);
