import { createContext } from 'react';

import { State } from '../types/State';

export interface AuthContextData {
  readonly state?: Partial<State>;
  readonly updateState: (state: Partial<State>) => void;
}

export const defaultState: Partial<State> = {
  user: undefined,
  isReady: false,
  token: undefined,
};

export const AuthContext = createContext<AuthContextData>({} as AuthContextData);
