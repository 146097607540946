import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button, Grid, Paper, InputLabel, Divider, SvgIcon } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import { ReactComponent as keyIcon } from '@app/app/images/icons/icon-key.svg';
import { useIsMobile } from '@app/app/hooks/useIsMobile';
import { useAuth } from '@app/auth';
import { useModal } from '@app/ui/modal';
import { generalInfoItems } from '@app/user/constants';
import { ChangeEmailModal } from '@app/auth/components/ChangeEmailModal';

import { ChangePasswordModal } from './ChangePasswordModal';

export const Profile = () => {
  const { t } = useTranslation('common');
  const isMobile = useIsMobile();
  const { isOpen: isOpenPasswordModal, close: closePasswordModal, show: showPasswordModal } = useModal();
  const { isOpen: isOpenEmailModal, close: closeEmailModal, show: showEmailModal } = useModal();
  const { user, application } = useAuth();

  const userData: Record<any, any> = {
    firstName: application?.firstName || '',
    lastName: application?.lastName || '',
    email: user.email,
    phone: application?.phone || '',
  };

  return (
    <>
      <Typography variant="h1" mb={4}>
        {t('profile.page.title')}
      </Typography>
      <Paper
        sx={{
          wordWrap: 'break-word',
          borderRadius: {
            md: '20px',
          },
          p: {
            xs: 2,
            sm: 3,
            lg: 5,
          },
          mb: 5,
        }}
        variant="outlined"
        elevation={0}
      >
        <Typography variant="h3" mb={3}>
          {t('user.page.single.information')}
        </Typography>

        <Grid container direction={isMobile ? 'column' : 'row'} spacing={isMobile ? 3 : 0}>
          {user &&
            generalInfoItems.map((item, index) => {
              const itemName = item.split('.').pop();
              const lastChild = index === generalInfoItems.length - 1;

              return (
                <Fragment key={item}>
                  <Grid item xs md={lastChild ? 3 : 2}>
                    <InputLabel>{t(`application.field.${itemName}.label`)}</InputLabel>
                    <div>{itemName ? userData[itemName] : '-'}</div>
                  </Grid>

                  {!lastChild && !isMobile && (
                    <Divider
                      sx={{
                        my: {
                          xs: 1,
                          sm: 0,
                        },
                        mx: {
                          sm: 2,
                          lg: 3,
                        },
                      }}
                      orientation="vertical"
                      flexItem
                    />
                  )}
                </Fragment>
              );
            })}
        </Grid>
        <Grid container pt={4} spacing={2}>
          <Grid item xs={12} sm="auto">
            <Button
              className="filledTonal"
              color="primary"
              variant="contained"
              sx={{
                width: {
                  xs: '100%',
                  sm: 'auto',
                },
              }}
              endIcon={<SvgIcon component={keyIcon} viewBox="0 0 24 24" />}
              onClick={showPasswordModal}
            >
              {t('profile.button.changeMyPassword')}
            </Button>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Button
              className="filledTonal"
              color="secondary"
              variant="contained"
              sx={{
                width: {
                  xs: '100%',
                  sm: 'auto',
                },
              }}
              endIcon={<MailOutlineIcon style={{ fontSize: 18 }} />}
              onClick={showEmailModal}
            >
              {t('profile.button.changeMyEmail')}
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <ChangePasswordModal open={isOpenPasswordModal} onClose={closePasswordModal} />
      <ChangeEmailModal open={isOpenEmailModal} onClose={closeEmailModal} />
    </>
  );
};
