import { ReactNode } from 'react';
import { SnackbarProvider as Provider } from 'notistack';

import { SnackbarsPubSub } from './PubSub';

export interface SnackbarsProviderProps {
  readonly children: ReactNode;
}

export const SnackbarsProvider = ({ children, ...props }: SnackbarsProviderProps) => {
  return (
    <Provider
      maxSnack={3}
      preventDuplicate
      autoHideDuration={10000}
      dense={false}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    >
      <>
        {children}
        <SnackbarsPubSub />
      </>
    </Provider>
  );
};
