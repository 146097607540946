import { ReactNode, useCallback } from 'react';
import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

import { Modal, ModalProps } from '@app/ui/modal';

export interface ConfirmationModalProps extends ModalProps {
  readonly title?: ReactNode;
  readonly description: ReactNode;
  readonly onSubmit: (close: () => void) => void;
  readonly isLoading?: boolean;
  readonly cancelButtonText?: ReactNode;
  readonly submitButtonText?: ReactNode;
}

export const ConfirmationModal = ({
  open,
  onClose,
  onSubmit,
  title,
  description,
  cancelButtonText,
  submitButtonText,
  isLoading,
  ...props
}: ConfirmationModalProps) => {
  const { t } = useTranslation('common');

  const handleClose = useCallback(() => {
    onClose('closeButtonClick');
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    onSubmit(() => onClose('submitClick'));
  }, [onSubmit, onClose]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={title}
      actions={
        <>
          <Button color="primary" onClick={handleClose}>
            {cancelButtonText || t('general.button.cancel')}
          </Button>
          <LoadingButton loading={isLoading} variant="contained" color="primary" onClick={handleSubmit}>
            {submitButtonText || t('general.button.ok')}
          </LoadingButton>
        </>
      }
      {...props}
    >
      {description}
    </Modal>
  );
};
