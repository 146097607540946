import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import { Application } from '../types/Application';
import { ApplicationSurveyFlagEnum, ApplicationSurveyEnum } from '../constants';
import { APPLICATION_UPDATE_SURVEY_FLAG } from '../gql';

export const useSurveyFlag = ({ id, survey }: { id: number; survey: ApplicationSurveyEnum }) => {
  const [applicationUpdateSurveyFlag] = useMutation<{
    applicationUpdateSurveyFlag: Partial<Application>;
  }>(APPLICATION_UPDATE_SURVEY_FLAG);

  const toggleFlag = useCallback(
    (flag: ApplicationSurveyFlagEnum) => {
      applicationUpdateSurveyFlag({ variables: { input: { id, survey, flag } } }).catch(e => console.error(e));
    },
    [id, survey],
  );

  return {
    toggleFlag,
  };
};
