import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

let initialized = false;

export const useGoogleTagManager = () => {
  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_KEY && !initialized) {
      TagManager.initialize({ gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_KEY });
      initialized = true;
    } else {
      console.error('Google Tag Manager failed to initialize.');
    }
  }, []);
};
