import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Document } from '@react-pdf/renderer';

import { ApplicationPdf } from '@app/application/constants';
import { Application } from '@app/application/types/Application';

import { applicationConfig } from '../../configs';
import { PdfPages } from './pages/PdfPages';
import { PdfCoverPage } from './pages/PdfCoverPage';

export interface ApplicationPdfDocumentProps {
  readonly data: Partial<Application>;
  readonly type: ApplicationPdf;
}

export const ApplicationPdfDocument = ({ data, type }: ApplicationPdfDocumentProps) => {
  const { t } = useTranslation('common');

  const pdfData = useMemo(() => ({ ...data, isPdf: true }), [data]);

  return (
    <Document
      title={`${t(`application.export.option.${type}`)} - ${t('application.pdf.title')}`}
      subject={`${t('application.pdf.subject')}`}
      language="en"
      creator="isc"
      producer="isc"
    >
      <PdfCoverPage data={pdfData} />
      <PdfPages data={pdfData} surveys={applicationConfig.surveys} type={type} />
    </Document>
  );
};
