import { useContext } from 'react';

import { UserRoleEnum } from '@app/user/constants';

import { AuthContext } from '../context';
import { State } from '../types/State';

export const useAuth = () => {
  const { state, updateState } = useContext(AuthContext);

  const user = state?.user as State['user'];

  const isAdmin = !!user && user.role === UserRoleEnum.admin;

  const application = user?.applications ? user?.applications[0] : undefined;

  return {
    user,
    updateState,
    isAdmin,
    application,
    isSignedIn: !!state?.token,
  };
};
