import dayJs from 'dayjs';

import { DynamicFormConfigSurvey, DynamicFormElementType, DynamicFormElementWidthMode } from '@app/dynamic';

import {
  ApplicationPdf,
  ApplicationSurveyEnum,
  CitizenshipStatusEnum,
  MilitaryStatusEnum,
  RelationshipStatusEnum,
  SmokingHabitEnum,
  WillingSmokeOutsideEnum,
} from '../constants';
import cardImage from '../images/card-application-partner.jpg';

export const partnerInfo: DynamicFormConfigSurvey<ApplicationSurveyEnum> = {
  name: ApplicationSurveyEnum.partnerInfo,
  meta: {
    cardImage,
    dependOnFields: ['status', 'firstName', 'lastName', 'relationshipStatus'],
  },
  visibleIf: ({ relationshipStatus }) =>
    [
      RelationshipStatusEnum.married,
      RelationshipStatusEnum.livingTogether,
      RelationshipStatusEnum.engaged,
      RelationshipStatusEnum.divorcedInRelationship,
    ].includes(relationshipStatus),
  pages: [
    {
      name: `${ApplicationSurveyEnum.partnerInfo}.information`,
      information: true,
      elements: [
        {
          name: `${ApplicationSurveyEnum.partnerInfo}.information`,
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            variables: {
              time: '5',
            },
            template: [['description.item1', 'description.item2']],
          },
        },
      ],
    },
    {
      name: 'partner.name',
      elements: [
        {
          name: 'partner.firstName',
          alias: 'firstName',
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 30,
          },
        },
        {
          name: 'partner.lastName',
          alias: 'lastName',
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 30,
          },
        },
        {
          name: 'partner.middleName',
          alias: 'middleName',
          optional: true,
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            length: 30,
          },
        },
      ],
    },
    {
      name: 'partner.phone',
      elements: [
        {
          name: 'partner.phone',
          alias: 'phone',
          type: DynamicFormElementType.phone,
          validIf: value => value.length >= 12,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
        },
      ],
    },
    {
      name: 'partner.dateOfBirth',
      elements: [
        {
          name: 'partner.dateOfBirth',
          alias: 'dateOfBirth',
          type: DynamicFormElementType.date,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            openTo: 'year',
            minDate: dayJs().subtract(68, 'years'),
            maxDate: dayJs().subtract(18, 'years'),
            views: ['year', 'month', 'day'],
          },
        },
      ],
    },
    {
      name: 'partner.socialSecurityNumber',
      elements: [
        {
          name: 'partner.socialSecurityNumber',
          alias: 'socialSecurityNumber',
          type: DynamicFormElementType.formattedText,
          validIf: value => value.length === 11,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            mask: '000-00-0000',
          },
        },
      ],
    },
    {
      name: 'partner.citizenshipStatus',
      elements: [
        {
          name: 'partner.citizenshipStatus',
          alias: 'citizenshipStatus',
          type: DynamicFormElementType.enumSelect,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            enum: CitizenshipStatusEnum,
          },
        },
      ],
    },
    {
      name: 'partner.hasIdentification',
      elements: [
        {
          noLabel: true,
          name: 'partner.hasIdentification',
          type: DynamicFormElementType.booleanRadio,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
        },
      ],
    },
    {
      name: 'partner.isEmployed',
      elements: [
        {
          noLabel: true,
          name: 'partner.isEmployed',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
              [ApplicationPdf.agency]: true,
            },
          },
        },
        {
          name: 'partner.employer',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ partner }) => partner?.isEmployed,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            multiline: true,
            length: 100,
          },
        },
        {
          name: 'partner.jobDescription',
          type: DynamicFormElementType.text,
          visibleIf: ({ partner }) => partner?.isEmployed,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            multiline: true,
            length: 100,
          },
        },
        {
          name: 'partner.monthlyIncome',
          type: DynamicFormElementType.text,
          visibleIf: ({ partner }) => partner?.isEmployed,
          meta: {
            pdf: {
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            multiline: true,
            length: 50,
          },
        },
      ],
    },
    {
      name: 'partner.militaryStatus',
      elements: [
        {
          noLabel: true,
          name: 'partner.militaryStatus',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
              [ApplicationPdf.agency]: true,
            },
          },
          props: {
            enum: MilitaryStatusEnum,
          },
        },
      ],
    },
    {
      name: 'partner.smokingHabits',
      showDescription: true,
      elements: [
        {
          noLabel: true,
          name: 'partner.smokingHabits',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            enum: SmokingHabitEnum,
          },
        },
        {
          name: 'partner.willingToSmokeOutside',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ partner }) =>
            [SmokingHabitEnum.nicotine, SmokingHabitEnum.cannabis, SmokingHabitEnum.both].includes(
              partner?.smokingHabits,
            ),
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            enum: WillingSmokeOutsideEnum,
          },
        },
        {
          name: 'partner.isUseCannabis',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
      ],
    },
    {
      name: 'partner.isGoodHealth',
      elements: [
        {
          noLabel: true,
          name: 'partner.isGoodHealth',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
      ],
    },
    {
      name: 'partner.hasHospitalizedForPsychiatric',
      elements: [
        {
          noLabel: true,
          name: 'partner.hasHospitalizedForPsychiatric',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
      ],
    },
    {
      name: 'partner.surrogacySupport',
      elements: [
        {
          noLabel: true,
          name: 'partner.surrogacySupport',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 200,
          },
        },
      ],
    },
    {
      name: 'partner.relationshipStatus',
      elements: [
        {
          noLabel: true,
          name: 'partner.relationshipStatus',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 500,
          },
        },
      ],
    },
  ],
};
