import { useField } from 'formik';
import { memo } from 'react';
import { Text as MuiText, TextProps as MuiTextProps } from '@app/ui/forms/components/Text';

import { useErrorTranslations } from '../../hooks/useErrorTranslations';

export type TextProps = MuiTextProps & { readonly name: string };

export const Text = memo(({ name, helperText, ...props }: TextProps) => {
  const [{ value, onChange }, { touched, error }] = useField(name);

  const errorHelperText = useErrorTranslations(name, error);

  const isError = Boolean(touched && errorHelperText);

  return (
    <MuiText
      {...props}
      value={value}
      name={name}
      onChange={onChange}
      error={isError}
      helperText={isError ? errorHelperText : helperText || ''}
    />
  );
});
