import { DynamicFormConfigSurvey, DynamicFormElementType, DynamicFormElementWidthMode } from '@app/dynamic';

import { ApplicationPdf, ApplicationSurveyEnum, WeeklyEatingOutFrequencyEnum } from '../constants';
import cardImage from '../images/card-application-about-you.jpg';

export const aboutYou: DynamicFormConfigSurvey<ApplicationSurveyEnum> = {
  name: ApplicationSurveyEnum.aboutYou,
  meta: {
    cardImage,
    dependOnFields: ['status', 'firstName', 'lastName'],
  },
  pages: [
    {
      name: `${ApplicationSurveyEnum.aboutYou}.information`,
      information: true,
      elements: [
        {
          name: `${ApplicationSurveyEnum.aboutYou}.information`,
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            variables: {
              time: '10-15',
            },
            template: [['description.item1', 'description.item2', 'description.item3']],
          },
        },
      ],
    },
    {
      name: 'languages',
      elements: [
        {
          noLabel: true,
          name: 'languages',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 100,
          },
        },
      ],
    },
    {
      name: 'personalityDescription',
      elements: [
        {
          noLabel: true,
          name: 'personalityDescription',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 300,
          },
        },
      ],
    },
    {
      name: 'positiveTraits',
      elements: [
        {
          noLabel: true,
          name: 'positiveTraits',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 300,
          },
        },
      ],
    },
    {
      name: 'hobbies',
      elements: [
        {
          noLabel: true,
          name: 'hobbies',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 300,
          },
        },
      ],
    },
    {
      name: 'selfCareActivities',
      elements: [
        {
          noLabel: true,
          name: 'selfCareActivities',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 300,
          },
        },
      ],
    },
    {
      name: 'familyActivities',
      elements: [
        {
          noLabel: true,
          name: 'familyActivities',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 300,
          },
        },
      ],
    },
    {
      name: 'childcareType',
      showDescription: true,
      elements: [
        {
          noLabel: true,
          name: 'childcareType',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 300,
          },
        },
      ],
    },
    {
      name: 'vacationPreferences',
      elements: [
        {
          noLabel: true,
          name: 'vacationPreferences',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 300,
          },
        },
      ],
    },
    {
      name: 'pets',
      showDescription: true,
      elements: [
        {
          noLabel: true,
          name: 'pets',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 300,
          },
        },
      ],
    },
    {
      name: 'foodPreferences',
      showDescription: true,
      elements: [
        {
          noLabel: true,
          name: 'foodPreferences',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 300,
          },
        },
      ],
    },
    {
      name: 'favoriteRestaurants',
      showDescription: true,
      elements: [
        {
          noLabel: true,
          name: 'favoriteRestaurants',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 300,
          },
        },
      ],
    },
    {
      name: 'weeklyEatingOutFrequency',
      elements: [
        {
          noLabel: true,
          name: 'weeklyEatingOutFrequency',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            enum: WeeklyEatingOutFrequencyEnum,
          },
        },
      ],
    },
    {
      name: 'favoriteColor',
      elements: [
        {
          noLabel: true,
          name: 'favoriteColor',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 300,
          },
        },
      ],
    },
    {
      name: 'favoriteSport',
      showDescription: true,
      elements: [
        {
          noLabel: true,
          name: 'favoriteSport',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 300,
          },
        },
      ],
    },
    {
      name: 'favoriteAnimal',
      elements: [
        {
          noLabel: true,
          name: 'favoriteAnimal',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 300,
          },
        },
      ],
    },
    {
      name: 'favoriteMusicGenre',
      elements: [
        {
          noLabel: true,
          name: 'favoriteMusicGenre',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 300,
          },
        },
      ],
    },
    {
      name: 'favoriteFlowers',
      elements: [
        {
          noLabel: true,
          name: 'favoriteFlowers',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 300,
          },
        },
      ],
    },
  ],
};
