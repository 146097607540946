import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';

import { error } from '@app/snackbars';
import { apolloClient } from '@app/query/configs/appoloClient';

import { applicationGetOne } from '../gql';
import { applicationConfig } from '../configs';
import { ApplicationPdf } from '../constants';
import { Application } from '../types/Application';
import { ApplicationPdfDocument } from '../components/pdf/ApplicationPdfDocument';
import { getPdfFields } from '../utils/survey';
import { prependImageExifMeta } from '../utils/pdf';

export const useGeneratePdf = () => {
  const { t } = useTranslation('common');
  const [isLoading, setIsLoading] = useState(false);

  const generatePdf = useCallback(
    async (id: number, type: ApplicationPdf) => {
      setIsLoading(true);
      const fields = getPdfFields(applicationConfig.surveys, type);

      try {
        const response = await apolloClient.query<{ applicationGetOne: Application }>({
          query: applicationGetOne(fields),
          variables: { id },
        });

        const data = JSON.parse(JSON.stringify(response?.data?.applicationGetOne)) || {};

        await prependImageExifMeta([data?.avatar, ...(data?.photos || [])]);

        const blob = await pdf(<ApplicationPdfDocument data={data} type={type} />).toBlob();

        await saveAs(blob, `${t(`application.export.option.${type}`)}.pdf`);
      } catch (e) {
        console.error(e);
        error(t('general.error.somethingWentWrong'));
      } finally {
        setIsLoading(false);
      }
    },
    [t],
  );

  return { generatePdf, isLoading };
};
